import axios from "axios";
import store from "@utils/store";
import CryptoJS from "crypto-js";
import { message } from "ant-design-vue";

const request = axios.create({
     baseURL: 'http://merits.cqzywd.cn',
    // baseURL: 'http://192.168.0.108',
    // baseURL: 'http://192.168.0.109:8085',
    // baseURL: 'http://192.168.0.222:8100',//小陈baby
    headers: {
        "content-type": 'application/json;charset=UTF-8'
    },
});
const baseUrl =
    process.env.NODE_ENV === "development"
        ? "merits.cqzywd.cn"
        : "merits.cqzywd.cn";
request.interceptors.request.use(function (config) {
    store.dispatch("user/change_isShowLoading", true);
    // 在发送请求之前做些什么
    config.headers["Content-Type"] = "application/json;charset=utf-8";

    const { mac_key, access_token, user_id } = store.getters.tokensInfo;
    if (access_token) {
        config.headers["Authorization"] = `Debug userid=${user_id}`
    }

    if (config.url.indexOf("/v0.1/tokens") > 0) {
        return config;
    }
    else {
        // if (access_token) {
        //     const key = mac_key;
        //     var randomStr = "",
        //         date = new Date().getTime(),
        //         chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
        //     for (var i = 0; i < 8; ++i) {
        //         randomStr += chars.charAt(Math.floor(Math.random() * 8));
        //     }
        //     var nonce = date + ":" + randomStr;
        //     var url_ = "";
        //     // console.log(config.params, "-----------")
        //     if (config.params) {
        //         url_ = config.url;
        //         var url_1 = "";
        //         Object.getOwnPropertyNames(config.params).forEach(function (key) {
        //             var link = "&" + key + "=" + config.params[key];
        //             url_1 += link;
        //         });
        //         url_ = url_ + "?" + url_1.substr(1).replace(" ", "");
        //     } else {
        //         url_ = config.url;
        //     }
        //     var mac =
        //         nonce +
        //         "\n" +
        //         config.method.toUpperCase() +
        //         "\n" +
        //         url_ +
        //         "\n" +
        //         baseUrl +
        //         "\n";
        //     const hash = CryptoJS.HmacSHA256(mac, key);
        //     var macAsign = CryptoJS.enc.Base64.stringify(hash);
        //     config.headers["Authorization"] =
        //         "MAC id=" +
        //         access_token +
        //         ', nonce="' +
        //         nonce +
        //         '", mac="' +
        //         macAsign +
        //         '"';
            return config;
        }
    // }
}, function (error) {
    store.dispatch("user/change_isShowLoading", false);
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
request.interceptors.response.use(function (response) {
    store.dispatch("user/change_isShowLoading", false);
    // 对响应数据做点什么
    var res = response.data;
    return response.data;
}, function (error) {
    store.dispatch("user/change_isShowLoading", false);
    // 对响应错误做点什么
    return Promise.reject(error);
});




export default request;
