  <template>
  <!-- 这里是账号设置下的电子签章页面 -->
  <div class="ele_s_container">
    <div>
      <div
        style="
          height: 60px;
          margin-bottom: 20px;
          border-bottom: 1px solid #dddddd;
        "
      >
        <p
          style="
            margin: 0 0 0 20px;
            font-size: 16px;
            color: #333;
            line-height: 60px;
            font-family: Microsoft YaHei;
            font-weight: 400;
          "
        >
          电子签章
        </p>
      </div>
      <div v-if="!isShowForm" class="ele_s_img_wrap">
        <div class="ele_s_img_wrap">
          <img :src="signatureImgUrl" alt="" />
          <div class="ele_s_icon_wrap">
            <a-icon type="form" class="el_s_f_icon el_s_ic" @click="onEdit" />
            <a-icon type="delete" class="el_s_ic" @click="onDel" />
          </div>
        </div>
      </div>
    </div>
    <a-form
      v-if="isShowForm"
      :form="form"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 12 }"
      @submit="handleSubmit"
    >
      <a-form-item label="电子签章" required>
        <div style="display: flex">
          <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :action="uploadUrl"
            :before-upload="beforeUpload"
            @change="handleChange"
          >
            <img
              v-if="imageUrl"
              :src="imageUrl"
              class="ele_up_img_size"
              alt="avatar"
            />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
          <span> 支持只支持600*400像素的 PNG格式图片... </span>
        </div>
      </a-form-item>
      <a-form-item label="签章密码">
        <a-input
          placeholder="请输入密码"
          v-decorator="[
            'sign_password',
            { rules: [{ required: true, message: '请输入密码' }] },
          ]"
        />
      </a-form-item>
      <a-form-item label="确认密码">
        <a-input
          placeholder="请输入密码"
          v-decorator="[
            'sign_password_again',
            { rules: [{ required: true, message: '请输入确认密码' }] },
          ]"
        />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 12, offset: 8 }">
        <a-button style="margin-right: 20px" @click="onCancel"> 取消 </a-button>
        <a-button type="primary" html-type="submit"> 保存 </a-button>
      </a-form-item>
    </a-form>

    <a-modal
      title="编辑电子签章"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form
        :form="m_form"
        id="m_form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 12 }"
      >
        <a-form-item label="电子签章" required class="ele_m_item">
          <div class="ele_m_up">
            <div class="ele_m_up_bd">
              <a-upload
                name="file"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                :action="uploadUrl"
                :before-upload="beforeUpload"
                @change="handleChange"
              >
                <img
                  v-if="imageUrl"
                  :src="imageUrl"
                  class="ele_up_img_size"
                  alt="avatar"
                />
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传图片</div>
                </div>
              </a-upload>
            </div>
            <span class="ele_mu_text">
              支持只支持600*400像素的 PNG格式图片...
            </span>
          </div>
        </a-form-item>
        <a-form-item label="签章密码" class="ele_m_item">
          <a-input
            placeholder="请输入密码"
            v-decorator="[
              'sign_password',
              { rules: [{ required: true, message: '请输入密码' }] },
            ]"
          />
        </a-form-item>
        <a-form-item label="确认密码" class="ele_m_item">
          <a-input
            placeholder="请输入密码"
            v-decorator="[
              'sign_password_again',
              { rules: [{ required: true, message: '请输入确认密码' }] },
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import HTTP from "@httpServer/index";
// import HTTP from "@httpServer/request";
import httpServer from "@httpServer/serverConfig";
import { mapState } from "vuex";
import { formatterParams, delConfirm } from "@utils/utils";
import CryptoJS from "crypto-js";
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default {
  data() {
    var _self = this;
    return {
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      m_form: this.$form.createForm(this, { name: "m_form" }),
      loading: false,
      imageUrl: "",
      visible: false,
      file_rel_list: {},
      m_file_rel_list: {},
      uploadUrl: httpServer.file.fileBase + httpServer.file.upload,
      handleChange(info) {
        if (info.file.status === "uploading") {
          _self.loading = true;
          return;
        }
        if (info.file.status === "done") {
          // Get _self url from response in real world.
          getBase64(info.file.originFileObj, (imageUrl) => {
            _self.imageUrl = imageUrl;
            _self.loading = false;
          });
        }
        var { fileList } = info;
        if (fileList[0] && fileList[0].response) {
          var res = fileList[0].response;
          if (res.code == "0000") {
            var data = res.data;
            var { file_name, file_type, file_size, id, file_path } = data;
            if (_self.visible) {
              _self.m_file_rel_list = {
                file_name,
                file_type,
                file_size,
                file_id: id,
              };
            } else {
              _self.file_rel_list = {
                file_name,
                file_type,
                file_size,
                file_id: id,
              };
            }
          }
        }
      },
      beforeUpload(file) {
        const isJpgOrPng =
          file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
          _self.$message.error("只能上传JPG格式图片!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          _self.$message.error("图片大小不能超过2MB!");
        }
        return isJpgOrPng && isLt2M;
      },
      signatureInfo: {},
      isShowForm: false,
      signatureImgUrl: "",
    };
  },
  ...mapState({
    // 缓存机构Id
    userInfo: (state) => state.user.userInfo,
  }),
  created() {
    this._getSign();
  },
  methods: {
    _getSign() {
      var user_id = this.$store.getters.userInfo.user_id;
      var url = httpServer.signature.sign(user_id);
      HTTP.get(url).then(async (res) => {
        if (res.code == "0000" && res.data) {
          this.isShowForm = false;
          this.signatureInfo = res.data;
          var fileList = this.signatureInfo.file_rel_list[0];
          // this.signatureImgUrl = await this.changeImgaeUrl(fileList.file_id);
          this.signatureImgUrl = this._showpic(fileList.file_id);
        } else {
          this.isShowForm = true;
        }
      });
    },
    _showpic(id) {
      var url = httpServer.file.fileBase + httpServer.file.showpic(id);
      return HTTP.get(url);
    },
    changeImgaeUrl(id) {
      return new Promise((resolve, reject) => {
        HTTP.get(
          httpServer.file.fileBase + httpServer.file.getFile2Id(id)
        ).then((res) => {
          if (res.code == "0000") {
            resolve("data:image/png;base64," + res.data.data);
          } else {
            reject("err");
          }
        });
      });
    },
    //创建
    handleSubmit(e) {
      e.preventDefault();
      if (!this.file_rel_list.file_id) {
        this.$message.error("请上传签章图片");
        return;
      }
      var _self = this;
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.sign_password != values.sign_password_again) {
            _self.$message.error("两次密码不一致，请重新输入");
            return;
          }
          var url = httpServer.signature.create;
          var opts = {
            sign_password: values.sign_password,
            file_rel_list: [_self.file_rel_list],
          };
          HTTP.post(url, opts).then((res) => {
            if (res.code == "0000") {
              this._getSign();
              this.$message.success("创建成功");
              this.imageUrl = "";
            } else {
              this.$message.success(res.message);
            }
          });
        }
      });
    },
    //编辑
    handleOk() {
      if (!this.m_file_rel_list.file_id) {
        this.$message.error("请上传签章图片");
        return;
      }
      var _self = this;
      this.m_form.validateFields((err, values) => {
        if (!err) {
          if (values.sign_password != values.sign_password_again) {
            _self.$message.error("两次密码不一致，请重新输入");
            return;
          }
          _self._checkPwd(values.sign_password, _self.m_file_rel_list);
        }
      });
    },
    _checkPwd(password, file) {
      var userInfo = this.$store.getters.userInfo;
      var opts = {
        user_id: userInfo.user_id,
        password: CryptoJS.MD5(password).toString(),
      };
      HTTP.put(httpServer.signature.check, opts).then((res) => {
        if (res.code == "0000" && res.data.status == "SUCCESS") {
          this._updateImg(file, userInfo);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    _updateImg(file, userInfo) {
      var url = httpServer.signature.update(userInfo.user_id);
      var opts = {
        user_id: userInfo.user_id,
        user_name: userInfo.user_name,
        file_rel_list: [file],
      };
      HTTP.put(url, opts).then((res) => {
        if (res.code == "0000" && res.code == "SUCCESS") {
          this.$message.success("修改成功");
          this._getSign();
          this.handleCancel();
        } else {
          this.$message.success(res.message);
        }
      });
    },
    async onEdit() {
      this.visible = true;
      var fileList = this.signatureInfo.file_rel_list[0];
      this.imageUrl = await this.changeImgaeUrl(fileList.file_id);
    },
    handleCancel() {
      this.visible = false;
      setTimeout(() => {
        this.m_form.resetFields();
        this.imageUrl = "";
      }, 200);
    },
    onDel() {
      var user_id = this.$store.getters.userInfo.user_id;
      delConfirm(
        this,
        "确认要删除电子签章吗吗？",
        "删除后将不能恢复，请谨慎操作。",
        this.onHandleDel,
        user_id
      );
    },
    onHandleDel(cf, user_id, _self) {
      var url = httpServer.signature.delete(user_id);
      HTTP.delete(url).then((res) => {
        if (res.code == "0000" && res.data.status == "SUCCESS") {
          _self.$message.success("删除成功");
          _self._getSign();
          cf.destroy();
        } else {
          _self.$message.success(res.message);
        }
      });
    },
    onCancel() {
      this.form.setFieldsValue({
        sign_password:'',
        sign_password_again:'',
        
      });
      this.file_rel_list = {};
    },
  },
};
</script>
<style lang="less">
.ele_s_container {
  .ele_s_img_wrap {
    position: relative;
    width: 100%;
    height: 300px;
    .ele_s_img_wrap {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 80%;
      height: 200px;
      border: 1px solid #ccc;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .ele_s_icon_wrap {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 10;
      font-size: 20px;
      .el_s_f_icon {
        margin-right: 20px;
      }
      .el_s_ic {
        cursor: pointer;
        &:hover {
          color: #02d6dc;
        }
      }
    }
  }
}
.ele_up_img_size {
  width: 104px;
  height: 104px;
  object-fit: contain;
}
#m_form {
  .ele_m_item {
    display: flex;
    .ant-form-item-label {
      width: 100px;
    }
    .ant-form-item-control-wrapper {
      flex: 1;
    }
    .ele_m_up {
      display: flex;
      .ele_m_up_bd {
        width: 104px;
        height: 104px;
      }
      .ele_mu_text {
        margin-left: 30px;
        font-size: 16px;
        color: #999999;
      }
    }
  }
}
</style>