import CryptoJS from 'crypto-js'
// import HTTP from "@httpServer";
import HTTP from "@httpServer/request";
import httpServer from "@httpServer/serverConfig";
import store from "../index";
import { message } from "ant-design-vue";

const getToken = (params) => {
  return HTTP.post(httpServer.login.getTokens, params).then(res => { //通过用户名密码 ，请求token
    if (res.code == "UC/USER_LOGIN_NOT_ALLOWED" || res.code == "UC/MEMBER_PASSWORD_ERROR") {
      message.warn(res.message);
    }
    // console.log('token', res.data)
    return res.data ? res.data : {}
  })
}
const getUserInfo = (userId) => { //获取 用户信息
  return HTTP.get(httpServer.login.getUserInfo(userId)).then(res => {
    return res
  })
}

const getUserPer = (userId) => { //获取 用户权限信息
  return HTTP.get(httpServer.login.getUserPer, {
    headers: {
      "HD-App-Code": 'www.merits.cqzywd.cn'
    }
  }).then(res => {
    return res.data ? res.data : {}
  })
}
const getCount = () => {
  var url = httpServer.messageCenter.getMessage + `&$offset=0&$limit=1${encodeURI("&$filter=read_status eq false")}`;
  return HTTP.get(url, {
    headers: {
      "HD-App-Code": 'www.merits.cqzywd.cn'
    }
  }).then((res) => {
    if (res.code == "0000") {
      return res.data.count || 0
    }
  });
}
const state = { //定义登录用户相关state
  userInfo: {},  //用户信息
  tokensInfo: {}, //token相关
  perInfo: {}, //权限相关
  // userImage:"",//用户头像
  msgCount: 0,//未读消息数
  coverMaskIsShow: true,//弹窗是否是第一次显示
  isShowLoading: false,
  innerIsShowLoading:true,
  msgInfo:{},
  jumpPageUrlOpts:null,//跳转消息
}
const mutations = {
  ["SET_MASK"](state, bool) {
    state.coverMaskIsShow = bool;
  },
  ['USER_LOGIN'](state, data) {
    state.userInfo = { ...data }
  },
  ['SET_SESSION_TOKEN'](state, data) {
    state.tokensInfo = { ...data }
  },
  ['SET_PER'](state, data) {
    state.perInfo = { ...data }
  },
  ['SET_LOCAL_TOKEN'](state, data) {
    localStorage.setItem('tokensInfo', JSON.stringify(data))
  },
  ["SET_LOCAL_UINFO"](state, data) {
    localStorage.setItem('UINFO', JSON.stringify(data))
  },
  ['USER_EXIT'](state) {  //用户登出
  
    localStorage.removeItem('tokensInfo')
    state.tokensInfo = {}
    state.userInfo = {} 


  },
  ["USER_CLEAR_UINFO"](state, data) {
    localStorage.removeItem("UINFO");
  },
  ["SET_MSG_COUNT"](state, num) {
    console.log(num, "??????????????????")
    state.msgCount = num;
  },
  ["SET_LOADING"](state, bool) {
    state.isShowLoading = bool;
  },
  ['SET_IS_INNER_LOADING'](state, bool) {
    
    state.innerIsShowLoading = bool;
    // console.log(state, bool,"yyyyy")
  },
  ['SET_MAG_INFO'](state, info) {
    state.msgInfo = info;
  },
  ["SET_URL"](state, opts) {
    state.jumpPageUrlOpts = opts;
  }
}

const actions = {
  async userLogin({ commit }, data) {
    let { login_name, password, rememberPsw } = data,
      params = { login_name, password: CryptoJS.MD5(password).toString() }, //组装请求参数 ，密码 md5 加密
      tokens = await getToken(params), //请求token
      userPer = {},
      userInfo = {}; // 用户信息容器

    //session 存储token
    commit('SET_SESSION_TOKEN', tokens);
    let { user_id } = tokens;
    if (!user_id) return false;

    //如果勾选记住密码 ，则本地存储local
    if (rememberPsw) {
      tokens.rememberPsw = rememberPsw;
      commit('SET_LOCAL_TOKEN', tokens);
      commit('SET_LOCAL_UINFO', { login_name, password });
    } else {
      commit('USER_CLEAR_UINFO');
    }
    // 请求用户信息
    userInfo = await getUserInfo(user_id)
    //请求用户权限列表
    userPer = await getUserPer();

    //session 存储用户信息
    commit('USER_LOGIN', userInfo.data || {});
    //存储权限
    commit("SET_PER", userPer);
    return userInfo
  },

  async setLocalTokens({ commit }, data) {
    commit('SET_SESSION_TOKEN', data);
    // 请求用户信息
    let userInfo = await getUserInfo(data.user_id)
    //session 存储用户信息
    commit('USER_LOGIN', userInfo);

    return userInfo
  },
  async userMagCount({ commit }) {
    //获取未读消息条数
    var count = await getCount();
    commit("SET_MSG_COUNT", count);
  },
  userExit({ commit }) {
    commit('USER_EXIT');
    setTimeout(() => {
      commit('SET_MASK', true);
    }, 500);
  },
  setMask({ commit }, bool) {
    commit('SET_MASK', bool);
  },
  change_isShowLoading({ commit }, bool) { 
    commit('SET_LOADING', bool)
  },
  change_innerLoading({commit}, bool) {
    commit("SET_IS_INNER_LOADING", bool);
  },
  change_msgInfo({commit}, info) {
    commit("SET_MAG_INFO", info);
  },
  change_url({commit}, opts) {
    commit('SET_URL', opts);
  }

}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
