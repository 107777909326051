var api = require("!../node_modules/_style-loader@1.3.0@style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../node_modules/_mini-css-extract-plugin@0.9.0@mini-css-extract-plugin/dist/loader.js!../node_modules/_css-loader@3.6.0@css-loader/dist/cjs.js!../node_modules/_vue-loader@15.11.1@vue-loader/lib/loaders/stylePostLoader.js!../node_modules/_postcss-loader@3.0.0@postcss-loader/src/index.js??ref--2-3!../node_modules/_less-loader@6.2.0@less-loader/dist/cjs.js??ref--2-4!../node_modules/_vue-loader@15.11.1@vue-loader/lib/index.js??vue-loader-options!./app.vue?vue&type=style&index=0&id=2a2f1762&prod&lang=css");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



module.exports = content.locals || {};