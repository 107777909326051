<template>
  <!-- 这里是个人信息页面 -->
  <div class="userinfo">
    <a-button type="primary" @click="onBack">后退</a-button>
    <div class="userinfo_info">
      <div class="userinfo_top bg_fff">
         
        <div
          :class="
            this.$store.getters.userInfo.member_info.gender == 'FEMALE'
              ? 'imageUrlWoman'
              : 'userinfo_top_img'
          "
        ></div>
        <!-- <div class="clearfix">
          <a-upload
            :action="upLoadUrl"
            list-type="picture-card"
            :file-list="fileList"
            @preview="handlePreview"
            @change="handleChange"
          >
            <div v-if="fileList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传头像</div>
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </div> -->
        <div>
          <p>{{ this.$store.getters.userInfo.member_info.user_name }}</p>
          <p>{{ this.$store.getters.userInfo.member_info.login_name }}</p>
        </div>
      </div>
      <div>
        <a-tabs default-active-key="1" :tab-position="tabPosition">
          <a-tab-pane key="1" tab="重置密码">
            <div class="info bg_fff">
              <userPassword type="resetpwd" />
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>
<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
import httpServer from "@httpServer/serverConfig";
// import HTTP from "@httpServer/index";
import HTTP from "@httpServer/request";
import "./userInfo.less";
import personalInfo from "./personalInfo";
import userPassword from "./userPassword";
import electronicSeal from "./electronicSeal";
import { employeeManageRoot } from "@utils/root";
import { changeRoot } from "@utils/utils";
import { mapState } from "vuex";
export default {
  data() {
    return {
      previewVisible: false,
      previewImage: "",
      fileList: [],

      tabPosition: "left",
      isResetRoot: false,
      imageUrl: "",
      loading: false,
      upLoadUrl: "http://merits.cqzywd.cn" + httpServer.file.upload,
      file_rel_list: [],
      currentIndex: -1,
      currentItem: {},
    };
  },
  components: {
    personalInfo,
    userPassword,
    electronicSeal,
  },
  computed: {
    ...mapState({
      privileges: (state) => state.user.perInfo?.privileges,
    }),
  },
  created() {},

  mounted() {
    // console.log(this.upLoadUrl)
  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },

    //文件
    handleUploadChange({ fileList }) {
      this.file_rel_list = fileList;
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("只能上传图片类型!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("大小不能超过2兆!");
      }
      return isJpgOrPng && isLt2M;
    },
    _initRoot() {
      this.isResetRoot = changeRoot(this.privileges, employeeManageRoot.RESET);
    },
    callback(val) {
      console.log(val);
    },
    onBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less">
.userinfo_top_img {
  // margin: 0 20px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  background: url("~@public/man.png") no-repeat;
  background-size: cover;
}
.imageUrlWoman {
  // margin: 0 20px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  background: url("~@public/woman.png") no-repeat;
  background-size: cover;
}
</style>
