<template>
  <div class="item_container">
    <div class="item_wrap">
      <ul class="item_top_bar_wrap clearfix">
        <li
          :class="{ item_tb_item: true, item_is_active: active == 1 }"
          @click="onItem(1)"
        >
          <span>全部</span>
        </li>
        <li class="item_tb_line"></li>
        <li
          :class="{ item_tb_item: true, item_is_active: active == 2 }"
          @click="onItem(2)"
        >
          <span>未读</span>
        </li>
        <li class="item_tb_line"></li>
        <li
          :class="{ item_tb_item: true, item_is_active: active == 3 }"
          @click="onItem(3)"
        >
          <span>已读</span>
        </li>
      </ul>
      <div class="item_content_wrap">
        <a-list
          item-layout="horizontal"
          :data-source="data"
          class="item_list_wrap"
        >
          <a-list-item slot="renderItem" slot-scope="item, index">
            <a-list-item-meta class="item_list_meta">
              <div slot="description" class="item_l_desc">
                {{ item["message"]["params"]["content"] || "" }}
              </div>
              <div slot="title" class="item_l_title" @click=" onLookAll(item)">
                <span class="ilt_text">{{ item["message"]["content"] }}</span>
                <span class="ilt_time">{{
                  formatterDate(
                    item["message"]["create_time"],
                    "yyyy-mm-dd hh:mm"
                  )
                }}</span>
              </div>
              <div slot="avatar" class="msg_icon">
                <i class="msg_mask_cir" v-if="!item.read_status"></i>
                <i class="iconfont icon-baseline-language-1"></i>
              </div>
            </a-list-item-meta>
          </a-list-item>
        </a-list>
      </div>
      <div class="item_look_all" @click="onLookAll">查看全部通知>></div>
    </div>
  </div>
</template>
<script type="text/jsx">
// import HTTP from "@httpServer/index";
import HTTP from "@httpServer/request";
import httpServer from "@httpServer/serverConfig";
import { formatterParams, formatterDate } from "@utils/utils";

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    active: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    data() {
      return this.list;
    },
    msgInfo() {
      return this.$store.getters.msgInfo;
    },
  },
  data() {
    return {
      formatterDate,
    };
  },
  created() {},
  methods: {
    onLookAll() {
      this.$emit("lookAll", this.active);
    },
    onItem(num) {
      this.$emit("change", num);
    },
    onGoHref(row) {
      console.log(row);
      var href = row.message.href_url;
      var id = row.message.target;
      var url = href + id;
      var host = window.location.host;
      var protocol = window.location.protocol;
      window.location.href = protocol + "//" + host + "/#" + url;
    },
    onRead(item) {
      var that = this;
      var url = httpServer.messageCenter.read(item.id);
      HTTP.put(url).then((res) => {
        if (res.code == "0000") {
          var resData = res.data.message.params;
          resData.lawFlowModal = true;
          let name = res.data.message.params.departmentName;
          let infoUrl = httpServer.userManage.getAllDepartmentsOne;
          infoUrl += encodeURI(`?$filter=dept_name eq ${name}`);
          HTTP.get(infoUrl).then((res) => {
            if ((res.code = "0000")) {
              let info = res.data.items[0];
              this.$store
                .dispatch("user/change_msgInfo", {
                  resData,
                })
                .then(() => {
                  that.$router.push({
                    path: `/CZQ_code/DepartmentalMonthlyPlan/info/index?name=${info.dept_name}&id=${info.dept_id}`,
                  });
                });
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="less">
.item_container {
  position: relative;
  width: 500px;
  background: #fff;
  .item_wrap {
    .item_top_bar_wrap {
      display: flex;
      border-bottom: 1px solid #dddddd;
      li {
        padding: 10px 0;
        float: left;
        text-align: center;
        &.item_tb_item {
          flex: 1;
          cursor: pointer;
        }
        &.item_tb_line {
          margin: 10px 0;
          width: 1px;
          background: #ddd;
        }
        &.item_is_active {
          span {
            padding: 0 4px 10px;
            color: #00cacf;
            border-bottom: 3px solid #00cacf;
          }
        }
      }
    }

    .item_content_wrap {
      .item_list_wrap {
        .ant-list-item {
          padding: 16px 0;
          margin: 0 20px;
        }
        .ant-list-item-meta {
          align-items: center;
        }
        cursor: pointer;
        .item_l_title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .ilt_text {
            width: 250px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 200;
            color: #333333;
            overflow: hidden;
            text-overflow:clip;
            white-space: pre-wrap;
            display: block;
          }
          .ilt_time {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #8b8b8d;
          }
        }
        .msg_icon {
          position: relative;
          width: 48px;
          height: 48px;
          line-height: 48px;
          text-align: center;
          color: #fff;
          background: #ea5b5b;
          border-radius: 50%;
          font-size: 24px;
          .msg_mask_cir {
            position: absolute;
            top: 0;
            left: 0;
            width: 12px;
            height: 12px;
            background: #ff0000;
            border-radius: 50%;
          }
        }
        .item_l_desc {
          width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #8b8b8d;
        }
      }
    }
    .item_look_all {
      padding: 14px 20px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #808080;
      text-align: right;
      border-top: 1px solid #dddddd;
      cursor: pointer;
    }
  }
}
</style>
