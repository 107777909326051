<template>
  <!-- 这里是账号设置下的个人详情页面 -->
  <a-form
    :form="form"
    :label-col="{ span: 6 }"
    :wrapper-col="{ span: 12 }"
    @submit="handleSubmit"
  >
    <div style="height: 60px; border-bottom: 1px solid #dddddd">
      <p class="per_info_title">个人信息</p>
    </div>
    <div class="per_info_img">
      <div class="per_i_i_wrap">
        <div class="per_hover" v-show="!isFileShowLoading">
          +
          <input
            ref="ref_per_input_up"
            type="file"
            class="per_h_inp"
            @change="onChangeFile"
            accept="image/*"
          />
        </div>

        <img :src="imageUrl" style="" />
        <a-icon type="loading" v-if="isFileShowLoading" />
      </div>
    </div>
    <a-form-item label="账号">
      <a-input
        disabled
        v-decorator="['login_name', { required: true, message: '请输入账号!' }]"
      />
    </a-form-item>
    <a-form-item label="姓名">
      <a-input
        placeholder="请输入姓名"
        :maxLength="28"
        v-decorator="[
          'user_name',
          { rules: [{ required: true, message: '请输入姓名!' }] },
        ]"
      />
    </a-form-item>
    <a-form-item label="职位">
      <a-input
        placeholder="请输入职位"
        :maxLength="28"
        v-decorator="[
          'position',
          { rules: [{ required: true, message: '请输入职位!' }] },
        ]"
      />
    </a-form-item>
    <a-form-item label="电话">
      <a-input
        placeholder="请输入电话"
        :maxLength="11"
        @change="onCheckPhone"
        v-decorator="[
          'phone',
          { rules: [{ required: true, message: '请输入电话!' }] },
        ]"
      />
    </a-form-item>
    <a-form-item label="现居住地址">
      <a-input
        placeholder="请输入现居住地址"
        :maxLength="50"
        v-decorator="[
          'current_address',
          { rules: [{ required: true, message: '请输入现居住地址!' }] },
        ]"
      />
    </a-form-item>
    <a-form-item :wrapper-col="{ span: 12, offset: 8 }">
      <a-button style="margin-right: 20px" @click="cancle"> 取消 </a-button>
      <a-button type="primary" html-type="submit"> 保存 </a-button>
    </a-form-item>
  </a-form>
</template>

<script>
// import HTTP from "@httpServer/index";
import HTTP from "@httpServer/request";
import httpServer from "@httpServer/serverConfig";
import { formatterParams, kb2Mb } from "@utils/utils";
import { mapState } from "vuex";
export default {
  data() {
    return {
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      imageUrl: "",
      fileSize: 2,
      isFileShowLoading: false,
      file_rel_list: {},
      info: {},
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  created() {
    this.getUserDetailinfo();
  },
  methods: {
    cancle() {
      this.form.setFieldsValue({
        user_name: "",
        phone: "",
        position: "",
        current_address: "",
      });
    },
    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        // return;
        if (!err) {
          let reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
          if (values.phone && !reg.test(values.phone)) {
            this.$message.error("手机号码格式不正确");
            return;
          }
          var opts = {
            ...values,
            file_rel_list: this.file_rel_list,
          };
          let url = httpServer.userManage.editMyInfo(this.userInfo.user_id);
          HTTP.put(url, opts).then((res) => {
            if (res.code == "0000" && res.data.status == "SUCCESS") {
              this.$message.success("修改成功");
              this.getUserDetailinfo();
            }
          });
        }
      });
    },
    onCheckPhone(e) {
      var val = e.target.value;
      let reg = /^[1][3,4,5,7,8,9][0-9]{9}$/; 
      if (reg.test(val)) {
        this._checkPhone(val);
      }else {
        
      }
    },
    _checkPhone(val) { 
      var url = httpServer.userManage.phoneCheck(val);
      HTTP.post(url).then((res) => {
        if (!res.data) {
          this.$message.error(res.message);
        } 
      });
    },
    getUserDetailinfo() {
      const { userInfo } = this.$store.getters;
      let url = httpServer.userManage.getUserDetailinfo(userInfo.user_id);
      HTTP.get(url).then((res) => {
        console.log(res.data.member_info);
        var data = res.data.member_info;
        this.info = data;
        this.form.setFieldsValue({
          login_name: data.login_name,
          user_name: data.user_name,
          phone: data.phone,
          position: data.position,
          current_address: data.current_address,
        });
      });
    },
    onChangeFile(e) {
      var file = e.target.files[0];
      if (file) {
        this.upload(file);
      }
    },
    upload(file) {
      var { size } = file;
      let url = httpServer.file.upload;
      var formData = new FormData();
      formData.append("file", file);
      var f_size = size / 1024;
      if (f_size < this.fileSize) {
        this.$message.error("图片不能超过2M");
        return false;
      }
      this.isFileShowLoading = true;
      HTTP.post(url, formData).then(async (res) => {
        this.isFileShowLoading = false;
        if (res.code == "0000") {
          this.$refs.ref_per_input_up.value = "";
          if (res.data.msg) {
            this.$message.error(res.data.msg);
            return;
          }
          if (res.data) {
            var { file_name, file_type, file_size, id } = res.data;
            this.file_rel_list = {
              file_name,
              file_type,
              file_size,
              file_id: id,
            };
            console.log(this.file_rel_list);
            this.getBase64(file).then((res) => {
              this.imageUrl = res;
            });
          }
        }
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    getImgUrl(id) {
      if (!id) return;
      HTTP.get(httpServer.file.fileBase + httpServer.file.getFile2Id(id)).then(
        (res) => {
          if (res.code == "0000") {
            this.imageUrl = "data:image/png;base64," + res.data.data;
          }
        }
      );
    },
  },
};
</script>
<style lang="less">
.per_info_title {
  margin: 0 0 0 20px;
  font-size: 16px;
  color: #333;
  line-height: 60px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}
.per_info_img {
  display: flex;
  align-items: center;
  margin: 20px 0 10px 0;
  justify-content: center;
  .per_i_i_wrap {
    width: 90px;
    height: 90px;
    line-height: 90px;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    background-color: #ccc;
    .per_hover {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      transition: all 0.4s;
      width: 90px;
      height: 90px;
      line-height: 90px;
      text-align: center;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      cursor: pointer;
      opacity: 0;
      font-size: 24px;
      font-weight: bolder;
      color: #fff;
      overflow: hidden;
      .per_h_inp {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        width: 90px;
        height: 90px;
        opacity: 0;
        cursor: pointer;
      }
    }
    &:hover {
      .per_hover {
        opacity: 1;
      }
    }
  }

  img {
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 50%;
    background-color: #ccc;
    overflow: hidden;
  }
}
</style>
