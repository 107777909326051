/**
 * 全局公共方法
 * @author 谭邻宣
 * @date 2020/7/4 14:35
 **/

export function getUrlKey() { //截取url 参数
    let urlParams = {};
    let name, value, str = location.href, num = str.indexOf("?"); //取得整个地址栏
    str = str.substr(num + 1); //取得所有参数 stringvar.substr(start [, length ]
    let arr = str.split("&"); //各个参数放到数组里
    for (let i = 0; i < arr.length; i++) {
        num = arr[i].indexOf("=");
        if (num > 0) {
            name = arr[i].substring(0, num);
            value = arr[i].substr(num + 1);
            urlParams[name] = decodeURI(value)
        }
    }
    return urlParams;
}

export function changeTab(vm, params) {
    let { path, keepAlive } = params;
    if (!path) return false
    if (keepAlive) {  // 开启页面缓存
        vm.$store.dispatch('tagViews/addCacheView', vm.$options.name)
    }
    vm.$router.replace({ path })
}


export function formatterParams(params) {
    // console.log(params)
    let filterParams = [];
    for (let k in params) {
        let item = `${k} like %${params[k]}%`
        filterParams.push(item)
    }
    // console.log(filterParams)
    let i = encodeURI(filterParams.join(' and '))
    // let i = filterParams.join(' and ');
    console.log(i)
    return i
}
//kb 2 mb
export function kb2Mb(limit) {
    var size = "";
    if (limit < 0.1 * 1024) { //如果小于0.1KB转化成B
        size = limit.toFixed(2) + "B";
    } else if (limit < 0.1 * 1024 * 1024) {//如果小于0.1MB转化成KB
        size = (limit / 1024).toFixed(2) + "KB";
    } else if (limit < 0.1 * 1024 * 1024 * 1024) { //如果小于0.1GB转化成MB
        size = (limit / (1024 * 1024)).toFixed(2) + "MB";
    } else { //其他转化成GB
        size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB";
    }

    var sizestr = size + "";
    var len = sizestr.indexOf("\.");
    var dec = sizestr.substr(len + 1, 2);
    if (dec == "00") {//当小数点后为00时 去掉小数部分
        return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
    }
    return sizestr;
}

//删除弹窗
export const delConfirm = function (_self, title, content, ok, row, props) {
    const cf = _self.$confirm({
        ...props,
        title,
        content,
        icon: (h) => {
            return (
                <a-icon
                    type="close"
                    style={{
                        padding: "3px",
                        "font-size": "18px",
                        color: "#FFF",
                        borderRadius: "50%",
                        backgroundColor: "#FF1A2E",
                    }}
                />
            );
        },
        okText: "确 定",
        okType: "danger",
        cancelText: "取 消",
        onOk() {
            ok && ok(cf, row, _self);
        },
        onCancel() {
            if (typeof cancel == 'function') {
                cancel && cancel(cf, row);
            } else {
                cf.destroy();
            }

        },
    }); 



}
//时间
export function formatterDate(dateText, type="yyyy-mm-dd hh:mm:ss") {
    if (dateText == "" || dateText == undefined) return;
    var date = new Date(dateText),
        y = date.getFullYear(),
        mon = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2),
        h = ("0" + date.getHours()).slice(-2),
        m = ("0" + date.getMinutes()).slice(-2),
        s = ("0" + date.getSeconds()).slice(-2);
    
        var str = '';
        if (type == "yyyy-mm-dd hh:mm:ss") {
            str = y + "-" + mon + "-" + day + "  " + h + ":" + m + ":" + s;
        }else if(type == "yyyy-mm-dd") {
            str = y + "-" + mon + "-" + day;
        }  
        switch(type) {
            case "yyyy-mm-dd hh:mm:ss":
                str = y + "-" + mon + "-" + day + "  " + h + ":" + m + ":" + s;break;
            case "yyyy-mm-dd hh:mm":
                str = y + "-" + mon + "-" + day + "  " + h + ":" + m; break;
            case "yyyy-mm-dd":
                str = y + "-" + mon + "-" + day;break;
            case "hh:mm:ss":
                str = h + ":" + m + ":" + s;break;
            case "hh:mm":
                str = h + ":" + m;break;
        }
    return str;
}

//获取权限判断
export function changeRoot(all, cur, str) {
    if (str == 'root' && cur == undefined) return true;
    var flag = false;
    for (var i = 0; i < all.length; i++) {
        var temp = all[i];
        if (temp == cur) {
            flag = true;
        }
    }
    return flag;
}
var isNotEmpty = function (checkStr) {
    // console.log(checkStr, "------------")
    return (checkStr === null || checkStr === undefined || checkStr.length === 0)
}; 
export function formatUrl(field, fieldOperator) { 
    var searchParams = []; 
    for (var param in fieldOperator) {
        if (field.hasOwnProperty(param) && !isNotEmpty(field[param])) {
            var value = field[param];
            if (fieldOperator[param] == 'between') {
                searchParams.push([param, 'ge', value.split(' - ')[0]].join(' '));
                searchParams.push([param, 'le', value.split(' - ')[1]].join(' '));
                continue;
            }
            if (fieldOperator[param] == 'like') {
                value = '%' + value + '%';
            }   
            searchParams.push([param, fieldOperator[param], value].join(' '));
        }
    }
    if (searchParams.length > 0) {
        return "&$filter=" + searchParams.join(' and ')
    }
    return '';
}
export function deepClone(target) {
    return JSON.parse(JSON.stringify(target))
}
export function tDeepClone(origin, target) {
    var target = target || {},
        toStr = Object.prototype.toString,
        arrStr = '[object Array]';
    for (var prop in origin) {
        if (origin.hasOwnProperty(prop)) {
            if (origin[prop] !== 'null' && typeof (origin[prop]) == 'object') {
                target[prop] = (toStr.call(origin[prop]) == arrStr) == arrStr ? [] : {};
                tDeepClone(origin[prop], target[prop]);
            } else {
                target[prop] = origin[prop];
            }
        }
    }
    return target;
}
/**
 * 存储localStorage
 */
 export const setStore = (name, content) => {
	if (!name) return;
	if (typeof content !== 'string') {
		content = JSON.stringify(content);
	}
	window.localStorage.setItem(name, content);
}

/**
 * 获取localStorage
 */
export const getStore = name => {
	if (!name) return;
	return window.localStorage.getItem(name);
}
// 文件
// export const documentProcessing