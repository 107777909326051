/**
 * mock data ,menu 菜单配置
 * @author 谭邻宣
 * @date 2020/7/6 14:17
 **/ 
const pageRouter = [
  /**
   * kt
   * kt
   */
   {
    title: "我的工作",
    name: "MyJob",
    icon: "iconfont icon-gongzuo1",
    isMenu: true, //是否渲染进导航栏
    children: [
      {
        title: "待我审核",
        isMenu: true, // 是否渲染进导航栏
        name: "waitingReview",
        component: () =>
          import("@pages/CZQ_code/MyJob/waitingReview"),
          path: "/MyJob/waitingReview/index", //路径
      },
      {
        title: "我已审核",
        isMenu: true, // 是否渲染进导航栏
        name: "workers",
        component: () =>
          import("@pages/CZQ_code/MyJob/HaveReviewed"),
        path: "/MyJob/HaveReviewed/index", //路径
      },
    ],
  },
  {
    title: '集团目标任务',
    name: 'targetTask',
    component: () => import('@pages/KT_code/groupObjectivesAndTasks'),
    icon: "iconfont icon-jituan2",
    isMenu: true, //是否渲染进导航栏 
    path: '/groupObjectivesAndTasks',
    root: 'doors-group-task-manage',
  },
  {
    title: '公司目标任务',
    name: 'companyTask',
    component: () => import('@pages/KT_code/companyTargetTask'),
    icon: "iconfont icon-gongsi",
    isMenu: true, //是否渲染进导航栏 
    path: '/companyTargetTask',
    root: 'doors-company-task-manage',
  },
  {
    title: '市规资局目标任务',
    name: 'cityTask',
    component: () => import('@pages/KT_code/cityTargetTask'),
    icon: "iconfont icon-jituan1",
    isMenu: true, //是否渲染进导航栏 
    path: '/cityTargetTask',
    root: 'doors-bureau-task-manage',
  },
  {
    title: '交办任务',
    name: 'assignmentTask',
    component: () => import('@pages/KT_code/assignmentTask'),
    icon: "iconfont icon-outline-assignment-24px",
    isMenu: true, //是否渲染进导航栏 
    path: '/assignmentTask',
    root: 'doors-assigned-task-manage',
  },
  {
    title: "督办任务",
    name: "supervise",
    icon: "iconfont icon-baseline-assignment-24px",
    isMenu: true,
    component: () => import("@pages/CZQ_code/supervise/index"),
    path: "/supervise",
    root:'doors-supervisory-task-manage',
  },
  {
    title: "部门月计划",
    name: "DepartmentalMonthlyPlan",
    icon: "iconfont icon-bumen",
    isMenu: true,
    component: () => import("@pages/CZQ_code/DepartmentalMonthlyPlan"),
    path: "/CZQ_code/DepartmentalMonthlyPlan/index",
    root:'doors-deptMonth-task-manage',
  },
  
  {
    title: "部门月计划",
    name: "DepartmentalMonthlyPlan",
    icon: "iconfont icon-baseline-equalizer-24px",
    // isMenu:true,
    component: () => import("@pages/CZQ_code/DepartmentalMonthlyPlan/score"),
    path: "/CZQ_code/DepartmentalMonthlyPlan/score/index",
    root:'doors-deptMonth-task-manage',
  },
  {
    title: "部门月计划",
    name: "DepartmentalMonthlyPlan",
    icon: "iconfont icon-baseline-equalizer-24px",
    // isMenu:true,
    component: () => import("@pages/CZQ_code/DepartmentalMonthlyPlan/info"),
    path: "/CZQ_code/DepartmentalMonthlyPlan/info/index",
    root:'doors-deptMonth-task-manage',
  },
  {
    title: "调减计划",
    name: "makeChanges",
    icon: "iconfont icon-tiaojianluru",
    isMenu: true,
    component: () => import("@pages/CZQ_code/makeChanges"),
    path: "/makeChanges",
    root:'doors-reduce-task-manage',
  },
  {
    title: "节点管理",
    name: "nodeAdministration",
    icon: "iconfont icon-jiedianguanli_1",
    isMenu: true,
    component: () => import("@pages/CZQ_code/nodeAdministration"),
    path: "/CZQ_code/nodeAdministration/index",
    root:'doors-node-manage',
  },
  {
    title: "节点管理",
    name: "nodeAdministration",
    icon: "iconfont icon-baseline-equalizer-24px",
    // isMenu: true,
    component: () => import("@pages/CZQ_code/nodeAdministration/info"),
    path: "/CZQ_code/nodeAdministration/info/index",
    root:'doors-node-manage',
  },

  {
    title: "消息中心",
    name: "messageCenter",
    icon: "iconfont icon-lingdang",
    // isMenu: true, // 是否渲染进导航栏
    component: () => import("@pages/messageCenter"), // 引用 component
    path: "/messageCenter/index", //路径 
  }, 

  {
    path: 'userInfo',
    name: '账号设置',
    component: () => import('@pages/userManage/userList/userInfo.vue'),
    meta: { title: '账号设置', affix: true },
  }, 
  {
    title: "组织架构",
    name: "userManage",
    icon: "iconfont icon-baseline-people_outline-24px1",
    isMenu: true,
    component: () => import("@pages/userManage/userList"),
    path: "/userManage/userList",
    root: 'doors-human-manage', 
  },

  {
    title: "角色管理",
    name: "roleManagement",
    icon: "iconfont icon-jiaose",
    isMenu: true, // 是否渲染进导航栏
    component: () => import("@pages/roleManagement"), // 引用 component
    path: "/roleManagement/index", //路径
    root: 'doors-role-manage'
  }, 
  // {
  //   title: "demo",
  //   name: "demo",
  //   icon: "iconfont icon-jiaose",
  //   isMenu: true, // 是否渲染进导航栏
  //   component: () => import("@pages/demo"), // 引用 component
  //   path: "/cq/index", //路径 
  // }, 
];




export default pageRouter;
