import router from './router' // 挂载路由
import './router/permissions' // 挂载路由权限校验
import store from "./utils/store" //加载状态管理
import echarts from 'echarts' // 全局注册echarts 
import Loading from "@components/Loading";
import *as Ant from 'ant-design-vue';  
import app from './app'  //主页面
 
import 'ant-design-vue/dist/antd.less';  
import VueDraggableResizable from 'vue-draggable-resizable'
Vue.component('vue-draggable-resizable', VueDraggableResizable)
Vue.use(Ant)
Vue.component('cmpLoading', Loading)
import vcolorpicker from 'vcolorpicker'
Vue.use(vcolorpicker)
Vue.prototype.$message = Ant.message;
Vue.prototype.$echarts = echarts;
Vue.prototype.$confirm = config => {  //全局注册 confirm
    let params = {
        title: '提示',
        okText: '确认',
        cancelText: '取消',
    }
    return Ant.Modal.confirm({ ...params, ...config });
};
Vue.config.devtools = true;
Ant.message.config({
    duration: 2,// 持续时间
    top: `100px`, // 到页面顶部距离
    maxCount: 1 // 最大显示数, 超过限制时，最早的消息会被自动关闭
});
new Vue({
    el: '#app',
    router,
    store,
    render: (h) => h(app)
})