<script type="text/jsx">
import "./index.less";
export default {
  props:{
    isShow:{
      type:Boolean,
      defaule:false
    }
  },    
  render(h) { 
    return (
      <div>
        {
        this.isShow && <div class="loading_container" >
        <div class="loading_wrap">
          <div class="loading_move">
            <span class="loading_dot"></span>
            <span class="loading_dot"></span>
            <span class="loading_dot"></span>
            <span class="loading_dot"></span>
          </div>
          <div class="loading_text">Loading...</div>
        </div>
      </div>
      } 
      </div>
    );
  },
};
</script>