/**
 * 路由控制
 * @author 谭邻宣
 * @date 2020/7/4 14:05
 **/
import Vue from 'vue'

import PageLayout from  '@src/layout'
import baseRouter from "./modules/baseRouter";
import mainRouter from "./modules/mainRouter";
import store from "@utils/store";
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
//Vue.use(Router)
const constantRoutes = [
    ...baseRouter,
    {
        path: '/',
        component: PageLayout,
        redirect: '/userManage/userList',
        children: [ 
            {
                path: '404',
                name: '404',
                component: () => import('@pages/common/error/404.vue'),
                meta: { title:'No Found', affix:false}
            },
           
            ...mainRouter
        ]
    }
]
export const asyncRoutes = []

const createRouter = () => new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})
const router = createRouter()
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}
router.beforeEach((to, from, next) => { 
    // console.log(to) //=> // 到哪个页面去？
    // console.log(from)// => // 从哪个页面来？
    next()// => // 一个回调函数
})
export default router