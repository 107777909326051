<template>
  <!-- 这里是账号设置下的重置密码页面 -->
  <a-form
    :form="form"
    :label-col="{ span: 6 }"
    :wrapper-col="{ span: 12 }"
    @submit="handleSubmit"
  >
    <div
      style="
        height: 60px;
        margin-bottom: 20px;
        border-bottom: 1px solid #dddddd;
      "
    >
      <p
        style="
          margin: 0 0 0 20px;
          font-size: 16px;
          color: #333;
          text-align:left;
          line-height: 60px;
          font-family: Microsoft YaHei;
          font-weight: 400;
        "
      >
        {{ title }}
      </p>
    </div>
    <a-form-item label="旧密码">
      <a-input
        placeholder="请输入密码"
        v-decorator="[
          'password',
          { rules: [{ required: true, message: '请输入密码!' }] },
        ]"
      />
    </a-form-item>
    <a-form-item label="新密码">
      <a-input
        placeholder="请输入密码"
        v-decorator="[
          'password_new',
          { rules: [{ required: true, message: '请输入密码!' }] },
        ]"
      />
    </a-form-item>
    <a-form-item label="确认新密码">
      <a-input
        placeholder="请输入密码"
        v-decorator="[
          'password_affirm',
          { rules: [{ required: true, message: '请输入确认新密码!' }] },
        ]"
      />
    </a-form-item>
    <a-form-item :wrapper-col="{ span: 12, offset: 8 }">
      <a-button style="margin-right: 20px" @click="cancel"> 取消 </a-button>
      <a-button type="primary" html-type="submit"> 保存 </a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import CryptoJS from "crypto-js";
// import HTTP from "@httpServer/index";
import HTTP from "@httpServer/request";
import httpServer from "@httpServer/serverConfig";
import { formatterParams } from "@utils/utils";
export default {
  props: {
    type: String,
  },
  data() {
    return {
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      title: "",
    };
  },
  created() {
    if (this.type == "qzpwd") {
      this.title = "签章密码";
    } else if (this.type == "resetpwd") {
      this.title = "重置密码";
    }
    // this.getUserDetailinfo()
  },
  methods: {
    cancel() {
      this.form.setFieldsValue({
        password:'',
        password_new: "",
        password_affirm: "",
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      console.log("////", this.type);
      this.form.validateFields((err, values) => {
        if (this.type == "resetpwd") {
          let reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d]{8,16}$/;
          if (!reg.test(values.password_new)) {
            this.$message.error(
              "无效密码，应由大小字母及数字中的三种组成(长度8-16位)"
            );
            return;
          }
        }
        if (values.password_new !== values.password_affirm) {
          this.$message.error("两次输入的密码不一致，请重新输入");
          return;
        }

        if (!err) {
          var params = {
            password: CryptoJS.MD5(values.password).toString(),
            password_new: values.password_new,
          };
          // return;
          let url = httpServer.userManage.changePassword;
          HTTP.put(url, params).then((res) => {
            if (res.code == "0000") {
              this.$message.success("修改成功");
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    getUserDetailinfo() {
      const { userInfo } = this.$store.getters;
      let url = httpServer.userManage.getUserDetailinfo(userInfo.user_id);
      HTTP.get(url).then((res) => {
        this.form.setFieldsValue({
          login_name: res.data.member_info.login_name,
          user_name: res.data.member_info.user_name,
        });
      });
    },
  },
};
</script>
