<template>
  <div class="app-main">
    <TagView />
    <div class="component">
      <div class="main-page">
        <transition name="slide-fade" mode="out-in">
          <keep-alive :include="keepAlive">
            <router-view :key="key" />
          </keep-alive>
        </transition>
        <CqModal
          :modalProps="modalMaskCoverProps"
          class="main_modal page_precise_clues_m"
          v-if="coverMaskIsShow && (hasRootTop || hasRootBt)"
        >
          <div class="table_mask_cover_container common_table_cust">
            <div
              class="table_mask_cover_top precise_clues_table_m czq_table"
              v-if="hasRootTop"
            >
              <div class="table_mask_cover_title">{{ year }}年集团目标任务</div>
              <!-- <CqTable :tableProps="coverTableTopProps" /> -->
              <DraggableTable :tableConfig="coverTableTopProps" />
            </div>
            <div class="table_mask_cover_bt precise_clues_table_m" v-if="hasRootBt">
              <div class="table_mask_cover_title">
                {{ year }}年规资局目标任务
              </div>
              <!-- <CqTable :tableProps="coverTableBtProps" />-->
              <DraggableTable :tableConfig="coverTableBtProps" /> 
            </div>
          </div>
        </CqModal>
      </div>
    </div>
    <!-- {/*查看弹窗 */} -->
    <div class="czq_lookmodal">
      <a-modal
        title="查看任务"
        :visible="visible"
        @ok="handleOk"
        width="800px"
        @cancel="handleCancel"
        class="czq_lookmodal_mo"
      >
        <div class="look_container">
          <ul v-if="!this.lookTempShow">
            <li v-for="(item, i) of this.lookTemp" :key="i">
              <label>{{ item["text"] }}</label>
              <div v-if="!item.type">{{ lookItem[item["param"]] }}</div>
              <div v-if="item.type && item.type == 'task_children'">
                <div v-for="(c, z) in lookItem.task_children" :key="z">
                  {{ c.task_name }}
                </div>
              </div>
              <div v-if="item.type && item.type == 'task_type'">
                {{ findEle(_self.taskType, lookItem[item["param"]]) }}
              </div>
              <div v-if="item.type && item.type == 'completion'">
                {{ lookItem[item["param"]] }}%
              </div>
            </li>
          </ul>
          <ul v-else>
            <li v-for="(item, i) of this.lookTempOne" :key="i">
              <label>{{ item["text"] }}</label>
              <div v-if="!item.type">{{ lookItem[item["param"]] }}</div>
              <div v-if="item.type && item.type == 'task_children'">
                <div v-for="(c, z) in lookItem.task_children" :key="z">
                  {{ c.task_name }}
                </div>
              </div>
              <div v-if="item.type && item.type == 'task_type'">
                {{ findEle(_self.taskType, lookItem[item["param"]]) }}
              </div>
              <div v-if="item.type && item.type == 'completion'">
                {{ lookItem[item["param"]] }}%
              </div>
            </li>
          </ul>
          <div class="speed">
            <p class="speed_p" @click="this.speedTextshow">
              {{ speedText }}
            </p>
            <div class="speed_div">
              <div v-if="this.speedTextshowVisi">
                <a-timeline>
                  <p
                    v-if="
                      this.task_completions == undefined ||
                      this.task_completions.length == 0
                    "
                    class="speed_span_p"
                  >
                    暂无记录
                  </p>
                  <a-timeline-item
                    v-for="(item, i) in this.task_completions"
                    :key="i"
                  >
                    <div slot="dot" class="speed_span">
                      <span>{{ item.current_completion }}%</span>
                    </div>
                    <p class="speed_span_p">
                      附件下载：
                      <span
                        v-for="(ele, e) of item.file_rel_list"
                        :key="e"
                        class="speed_file"
                        @click="onGetDownloadCode(ele)"
                      >
                        {{ ele.file_name }}
                      </span>
                    </p>
                    <div
                      class="stepInfo"
                      v-for="(v, k) of item.task_steps"
                      :key="k"
                    >
                      <div>
                        <p>{{ v.step_name }}</p>
                        <p>审核时间：{{ formatterDate(v.approve_time) }}</p>
                        <p v-if="v.draft_step_type">
                          任务类型：{{
                            tyepListShow[v["draft_step_type"]]["text"]
                          }}
                        </p>
                      </div>
                      <div>
                        <p
                          :class="
                            v.step_status == 'PASS'
                              ? 'PASS'
                              : v.step_status == 'WAIT'
                              ? 'WAIT'
                              : 'REJECT'
                          "
                        >
                          审核状态：
                          {{
                            v.step_status == "PASS"
                              ? "通过"
                              : v.step_status == "DRAFT_PASS"
                              ? "拟同意"
                              : v.step_status == "NOT_BEGUN"
                              ? "未开始"
                              : v.step_status == "WAIT"
                              ? "审批中"
                              : "驳回"
                          }}
                        </p>
                        <p>审核意见：{{ v.opinion }}</p>
                      </div>
                    </div>
                  </a-timeline-item>
                </a-timeline>
              </div>
            </div>
          </div>
          <div class="speed">
            <p class="speed_p" @click="this.reduTextshow">
              {{ reduText }}
            </p>
            <div class="speed_div">
              <div v-if="this.reduTextshowVisi">
                <a-timeline>
                  <p
                    v-if="
                      this.task_reduces == undefined ||
                      this.task_reduces.length == 0
                    "
                    class="speed_span_p"
                  >
                    暂无记录
                  </p>
                  <a-timeline-item
                    v-for="(item, i) in this.task_reduces"
                    :key="i"
                  >
                    <div slot="dot" class="speed_span">
                      <span>{{ i + 1 }}次</span>
                    </div>
                    <p class="speed_span_p">
                      附件下载：
                      <span
                        v-for="(ele, e) of item.file_rel_list"
                        :key="e"
                        class="speed_file"
                        @click="onGetDownloadCode(ele)"
                      >
                        {{ ele.file_name }}
                      </span>
                    </p>
                    <p class="speed_span_p">
                      调减理由： {{ item.reduce_reason }}
                    </p>
                    <div
                      class="stepInfo"
                      v-for="(v, k) of item.task_steps"
                      :key="k"
                    >
                      <div>
                        <p>{{ v.step_name }}</p>
                        <p>审核时间：{{ formatterDate(v.approve_time) }}</p>
                        <p v-if="v.draft_step_type">
                          任务类型：{{
                            tyepListShow[v["draft_step_type"]]["text"]
                          }}
                        </p>
                      </div>
                      <div>
                        <p
                          :class="
                            v.step_status == 'PASS'
                              ? 'PASS'
                              : v.step_status == 'WAIT'
                              ? 'WAIT'
                              : 'REJECT'
                          "
                        >
                          审核状态：
                          {{
                            v.step_status == "PASS"
                              ? "通过"
                              : v.step_status == "DRAFT_PASS"
                              ? "拟同意"
                              : v.step_status == "NOT_BEGUN"
                              ? "未开始"
                              : v.step_status == "WAIT"
                              ? "审批中"
                              : "驳回"
                          }}
                        </p>
                        <p>审核意见：{{ v.opinion }}</p>
                      </div>
                    </div>
                  </a-timeline-item>
                </a-timeline>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <a-button @click="handleOk">取消</a-button>
        </template>
      </a-modal>
    </div>
  </div>
</template>
<script type="text/jsx">
import TagView from "./tagView";
import CqModal from "@components/CqModal";
import CqTable from "@components/Table";
import Progress from "@components/KTProgress";
import httpServer from "@httpServer/serverConfig";
// import HTTP from "@httpServer/index";
import DraggableTable from "@components/draggableTable";
import HTTP from "@httpServer/request";
import { delConfirm, formatUrl, formatterDate, changeRoot } from "@utils/utils";
import { groupTaskRoot, bureauTaskRoot } from "@utils/root";
export default {
  name: "Main",
  data() {
    var _self = this;
    return {
      visible: false,
      lookTempShow: false,
      formatterDate,
      tyepListShow: {
        CITY_HALL: {
          text: "市政府相关部门交办任务",
        },
        GROUP_LEADER: { text: "集团领导交办任务" },
        DISTRICT_HALL: { text: "区政府主要领导交办任务" },
        COMPANY_LEADER: { text: "公司领导交办任务" },
        OTHER: { text: "其他" },
      },
      speedText: "完成进度记录 >",
      reduText: "调减进度记录 >",
      task_completions: [],
      lookItem: {},
      year: new Date().getFullYear(),
      lookTemp: [
        {
          text: "任务名称",
          param: "task_name",
        },
        {
          text: "任务子节点",
          param: "task_children",
          type: "task_children",
        },
        {
          text: "任务类型",
          param: "task_type",
          type: "task_type",
        },
        {
          text: "牵头部门",
          param: "lead_dept_name",
        },
        {
          text: "关联部门",
          param: "link_dept_names",
        },
        {
          text: "完成情况",
          param: "completion",
          type: "completion",
        },
      ],
      lookTempOne: [
        {
          text: "任务名称",
          param: "task_name",
        },
        {
          text: "任务子节点",
          param: "task_children",
          type: "task_children",
        },
        {
          text: "任务内容",
          param: "task_content",
        },
        {
          text: "任务类型",
          param: "task_type",
          type: "task_type",
        },
        {
          text: "牵头部门",
          param: "lead_dept_name",
        },
        {
          text: "关联部门",
          param: "link_dept_names",
        },
        {
          text: "完成情况",
          param: "completion",
          type: "completion",
        },
      ],
      taskType: [
        {
          text: "普通任务",
          value: "COMMON",
        },
        {
          text: "量化指标任务",
          value: "INDICATORS",
        },
      ],
      coverTableDataTop: [],
      coverTableDataBt: [],
      reduTextshowVisi: false,
      speedTextshowVisi: false,
      //弹窗 第一次打开
      modalMaskCoverProps: {
        props: {
          visible: true,
          title: "今年集团目标任务",
          width: "80%",
          maskClosable: false,
          footer: null,
        },
        on: {
          //事件
          cancel: () => {
            _self.maskCoverCancel();
          },
        },
      },
      padding: 32,
      coverTableTopProps: {
        columns: [
          {
            title: "序号",
            dataIndex: "index",
            align: "left",
            width: "60px",
            customRender: (text, record, index) => {
              return <span>{index + 1}</span>;
            },
          },
          {
            title: "任务名称",
            dataIndex: "task_name",
            key: "task_name",
            align: "left",
            width: "400px",
            ellipsis: true,
            customRender: (text, record) => {
              let width = 400 - this.padding + "px";
              return (
                <span class="text" style={{ width }} title={text}>
                  {text ? text : "-"}
                </span>
              );
            },
          },
          {
            title: "完成情况",
            dataIndex: "completion",
            key: "completion",
            align: "left",
            width: "150px",
            customRender: (text, { completion }) => {
              return <Progress percent={completion} />;
            },
          },
          {
            title: "牵头部门",
            dataIndex: "lead_dept_name",
            key: "lead_dept_name",
            width: "150px",
            align: "left",
            ellipsis: true,
          },
          {
            title: "关联部门",
            dataIndex: "link_dept_names",
            key: "link_dept_names",
            width: "150px",
            align: "left",
            ellipsis: true,
          },
          {
            title: "操作",
            dataIndex: "op",
            key: "op",
            align: "center",
            fixed: "right",
            width: "80px",
            customRender: (text, record) => {
              return (
                <div class="common_icon_op">
                  {
                    <span onClick={() => this.onLook(record, "group")}>
                      查看
                    </span>
                  }
                </div>
              );
            },
          },
        ],
        pagination: false,
        bordered: true,
        dataSource: [],
        scroll: { x: 1000},
        // scroll: { x: "calc(100% - 20px)", y: "calc(100vh - 390px)" },
      },

      coverTableBtProps: {
        columns: [
          {
            title: "序号",
            dataIndex: "index",
            align: "left",
            width: "60px",
            customRender: (text, record, index) => {
              return <span>{index + 1}</span>;
            },
          },
          {
            title: "任务名称",
            dataIndex: "task_name",
            key: "task_name",
            align: "left",
            width: "400px",
            ellipsis: true,
            customRender: (text, record) => {
              let width = 400 - this.padding + "px";
              return (
                <span class="text" style={{ width }} title={text}>
                  {text ? text : "-"}
                </span>
              );
            },
          },
          {
            title: "完成情况",
            dataIndex: "completion",
            key: "completion",
            width: "150px",
            align: "left",
            customRender: (text, { completion }) => {
              return <Progress percent={completion} />;
            },
          },
          {
            title: "牵头部门",
            dataIndex: "lead_dept_name",
            key: "lead_dept_name",
            width: "150px",
            align: "left",
          },
          {
            title: "关联部门",
            dataIndex: "link_dept_names",
            key: "link_dept_names",
            align: "left",
            width: "150px",
            ellipsis: true,
          },
          {
            title: "操作",
            dataIndex: "op",
            key: "op",
            align: "center",
            fixed: "right",
            width: "80px",
            customRender: (text, record) => {
              return (
                <div class="common_icon_op">
                  {
                    <span onClick={() => this.onLook(record, "city")}>
                      查看
                    </span>
                  }
                </div>
              );
            },
          },
        ],
        pagination: false,
        dataSource: [],
        bordered:true,
        scroll: { x: 1000},
      },
      hasRootTop: false,
      hasRootBt: false,
    };
  },
  components: {
    TagView,
    CqModal,
    CqTable,
    Progress,
    DraggableTable,
  },
  computed: {
    keepAlive() {
      return this.$store.getters.cachedViews;
    },
    key() {
      return this.$route.fullPath;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    coverMaskIsShow() {
      return this.$store.getters.coverMaskIsShow;
    },
    privileges() {
      return this.$store.getters.perInfo.privileges;
    },
  },
  watch: {},
  created() {
    this._initData();
  },
  mounted() {
    if (!this.userInfo.user_id) {
      this.$router.replace({ name: "login" });
    }
  },
  methods: {
    speedTextshow() {
      this.speedTextshowVisi = !this.speedTextshowVisi;
    },
    reduTextshow() {
      this.reduTextshowVisi = !this.reduTextshowVisi;
    },
    findEle(arr, task_type) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i]["value"] == task_type) {
          return arr[i]["text"];
        }
      }
    },
    _initRoot() {
      this.hasRootTop = changeRoot(this.privileges, groupTaskRoot.PT);
      this.hasRootBt = changeRoot(this.privileges, bureauTaskRoot.PT);
    },
    onLook(opts, type) {
      console.log(type, "type");
      type == "city" ? (this.lookTempShow = true) : (this.lookTempShow = false);
      this.visible = true;
      console.log(this.lookTempShow, "啦啦啦");
      this._getSingle(opts);
    },
    handleOk(e) {
      this.visible = false;
      this.speedTextshowVisi = false;
      this.reduTextshowVisi = false;
    },
    handleCancel(e) {
      this.visible = false;
      this.speedTextshowVisi = false;
      this.reduTextshowVisi = false;
    },
    //根据id 获取审核流程
    _getFlowIdList(id) {
      var url = httpServer.approvalProcess.getTaskDetail(id);
      return HTTP.get(url).then((res) => {
        return res.data.task_steps;
      });
    },
    _getSingle(opts) {
      if (opts.czqtype == "group") {
        var url = httpServer.groupTargetasks.getSingleDetail(opts.id);
      } else if (opts.czqtype == "city") {
        var url = httpServer.cityTargetTask.getSingleDetail(opts.id);
      }
      HTTP.get(url).then((res) => {
        if (res.code == "0000") {
          var data = res.data;
          this.lookItem = data;
          // console.log(data, "嘀嘀嘀");
          this.task_completions = data.task_completions; //进度记录
          if (data.task_completions) {
            data.task_completions.map(async (item, i) => {
              let data = await this._getFlowIdList(item.flow_id);
              this.task_completions.map((row, r) => {
                if (i == r) {
                  row.task_steps = data;
                }
              });
            });
          }
          this.task_reduces = data.task_reduces || []; //调减记录
          data.task_reduces.map(async (item, i) => {
            let rows = await this._getFlowIdList(item.flow_id);
            this.task_reduces.map((row, r) => {
              if (i == r) {
                row.task_steps = rows;
              }
            });
          });
        }
      });
    },
    maskCoverCancel() {
      this.$store.dispatch("user/setMask", false).then(() => {
        this.modalMaskCoverProps.props.visible = false;
      });
    },
    /**
     * /v0.1/group/task/list?count=true&$offset=0&$limit=20&$filter=year eq {year}  集团目标任务首页分页
/v0.1/prb/task/list?count=true&$offset=0&$limit=20&$filter=year eq {year}  市规资局目标任务首页分页  
     */
    joinUrl1() {
      var baseUrl = httpServer.cityTargetTask.getTaskList;
      var fieldOperator = {
        task_name: "like",
        year: "eq",
      };
      var field = {
        task_name: this.searchVal,
        year: this.currentYear,
      }; // 搜索框值
      var param = {};
      param = formatUrl(field, fieldOperator);
      baseUrl += encodeURI(param);
      return baseUrl;
    },
    joinUrl2() {
      var date = new Date();
      var baseUrl = httpServer.groupTargetasks.getTaskListAll(
        date.getFullYear()
      );
      var fieldOperator = {
        task_name: "like",
        year: "eq",
      };
      var field = {
        task_name: this.searchVal,
        year: this.currentYear,
      }; // 搜索框值
      var param = {};
      param = formatUrl(field, fieldOperator);
      baseUrl += encodeURI(param);
      return baseUrl;
    },
    _initData() {
      if (this.coverMaskIsShow) {
        this._getPrbTask();
        this._getGroupTask();
        this._initRoot();
      }
    },
    _getGroupTask() {
      HTTP.get(this.joinUrl2()).then((res) => {
        if (res.code == "0000") {
          res.data.items.map((item) => {
            item.czqtype = "group";
          });
          var data = res.data.items;
          this.coverTableTopProps.dataSource = data;
          console.log(this.coverTableTopProps, "dududu");
        }
      });
    },
    _getPrbTask() {
      var url = this.joinUrl1();
      HTTP.get(url).then((res) => {
        if (res.code == "0000") {
          res.data.items.map((item) => {
            item.czqtype = "city";
          });
          var data = res.data.items;
          this.coverTableBtProps.dataSource = data;
        }
      });
    },
    //文件下载
    onGetDownloadCode(v) {
      var url = httpServer.file.download(v.file_id);
      HTTP.get(url).then((res) => {
        if (res.code == "0000") {
          var down_code = res.data.down_code;
          this.download(down_code, v.file_name);
        }
      });
    },
    download(code, name) {
      var url = httpServer.file.fainlDownload(code);
      window.open(httpServer.file.fileBase + url);
    },
  },
};
</script>
<style lang="less">
.main_modal {
  .ant-modal-content {
    padding-bottom: 20px;
  }
  .table_mask_cover_container {
    height: 5.5rem;
    overflow: scroll;
    .table_mask_cover_top {
      margin-bottom: 26px;
    }
    .table_mask_cover_bt,
    .table_mask_cover_top {
      background: #f8f8f8;

      border: 1px solid #dddddd;
      .table_mask_cover_title {
        height: 54px;
        line-height: 54px;
        text-align: center;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        background: #f8f8f8;
      }
    }

    .ant-table-content {
      .ant-table-header.ant-table-hide-scrollbar,
      .ant-table-tbody {
        background: #f8f8f8;
        tr {
          th {
            background: #f8f8f8;
            border-top: 1px solid #dddddd;
          }
        }
      }
    }
    .ant-table-header > .ant-table-fixed,
    .ant-table-body {
      padding: 0 !important;
    }
  }
}
.speed_p {
  cursor: pointer;
}
.speed_file {
  cursor: pointer;
  margin-right: 10px;
}
.speed_file:hover {
  color: #b61f23;
}
/* .speed_div {
  display: flex;
} */
.stepInfo {
  display: flex;
  justify-content: space-around;
}
.stepInfo div {
  width: 50%;
  margin-bottom: 0.1875rem;
}
.speed_span_p {
  font-weight: bold;
}
.WAIT {
  color: #e98a06;
}
.czq_lookmodal_mo {
  .ant-modal {
    .ant-modal-content {
      .ant-modal-body {
        max-height: 5.275rem !important;
        overflow: auto;
        padding: 24px 24px 0 24px;
      }
    }
  }
}
/* 进度 */
.czq_lookmodal_mo {
  .ant-timeline-item-content {
    margin: 0 0 0 45px !important;
  }
}
.ant-modal {
  //弹框样式重写
  .ant-modal-title {
    font-size: 14px !important;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
}
.UN_DONE,
.REJECT {
  color: #b61f23;
}
.DONE,
.PASS {
  color: green;
}
.common_icon_op span {
  cursor: pointer;
  color: #999;
}
.common_icon_op span:hover {
  color: #b61f23;
}
.czq_table {
  overflow: auto;
}
.page_precise_clues_m {
  .precise_clues_table_m {
    background-color: #fff;
    // padding: 24px 56px 24px 56px;
    .cq_table .ant-table .ant-table-content .ant-table-scroll {
      border-left: none !important;
    }
    .ant-table-thead > tr > th {
      border-top: 1px solid #e8e8e8 !important;
    }

    .ant-table-body {
      &::-webkit-scrollbar {
        width: 8px !important;
        height: 8px !important;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 7px !important;
        background: rgba(183, 183, 183, 1) !important;
      }
      &::-webkit-scrollbar-track {
        //border-radius: 5px;
        background: rgba(229, 229, 229, 1) !important;
      }
    }
    .ant-table-tbody {
      color: rgba(46, 46, 53, 1) !important;
      tr {
        td {
          .text {
            // display: block;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            &.name {
              color: #1890ff;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
