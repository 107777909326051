<script type="text/jsx">
import pageRouter from "@router/router";
import { changeRoot, changeTab } from "@utils/utils";
import { mapState } from "vuex";
 
export default {
  name: "Aside",
  data() {
    return {
      isCollapse: false,
      menuData: [],
      openMenu: [], //左侧打开菜单的key
      renderMenu: (data) => {
        //递归返回菜单列表
        return data.map((item) => {
          let { children, name, title, isMenu, icon } = item;
          if (children && children.length) {
            return (
              isMenu && <a-sub-menu key={name}>
                <span slot="title">
                <span  class="anticon">
                    {icon && <i class={icon} style={{ marginRight: "17px" }} />}
                </span>

                  <span
                    style={{
                      marginLeft: !icon ? "23px" : "0px",
                      fontSize: "16px",
                    }}
                  >
                    {title}
                  </span>
                </span>
                {this.renderMenu(children)}
              </a-sub-menu>
            );
          } else {
            return (
              isMenu && (
                <a-menu-item key={name} onClick={() => this.handSelect(item)}>
                  <span class="anticon">
                    {icon && <i class={icon} style={{ marginRight: "17px" }} />}
                  </span>
                  <span
                    style={{
                      marginLeft: !icon ? "23px" : "0px",
                      fontSize: "16px",
                    }}
                  >
                    {title}
                  </span>
                </a-menu-item>
              )
            );
          }
        });
      },
    };
  },
  computed: {
    checkedMenu() {
      return this.$store.state.tagViews.activeView;
    },
    ...mapState({ 
      privileges: (state) => state.user.perInfo?.privileges,
    }), 
   defaultActive(){
     
   },
  },
  watch: {
    checkedMenu(newVal, oldVal) {
      let menuKey = newVal.path.split("/");
      menuKey.shift();
      this.openMenu = menuKey;
    },
  },
  created() {
    this.menuData = this.changeRouter(pageRouter);
  },
  methods: {
    changeRouter(data) { 
      return data.map((item, index)=> {
        let { children, name, title, isMenu, icon, root, path} = item;
        // let 
        let rNode = {
          children, name, title, 
          isMenu:isMenu ? changeRoot(this.privileges, root, 'root') : isMenu,
          icon, 
          root,
          path
        }
        if (children && children.length) {
          rNode.children = this.changeRouter(children);
        } 
        // console.log(rNode,'滴滴滴滴滴')
        return rNode;
      })
    },
    handSelect(item) {
      // this.$router.replace({ path: item.path });
      this.$router.push({
        path: item.path
      });
    },
    onOpenChange(key, keyPath) {
      this.openMenu = key;
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    toggleCollapsed() {
      this.isCollapse = !this.isCollapse;
      this.$emit("onChange", this.isCollapse);
    },
  },
  // <a-button
  //         type="primary"
  //         style="margin-bottom: 16px;text-align:center"
  //         onClick={this.toggleCollapsed}
  //       >

  //       </a-button>
  render() {
    return (
      <div class="app-aside">
        <div class="app_aside_menu_wrap">
         <a-icon onClick={this.toggleCollapsed} class="app_aside_menu_icon" type={this.isCollapse ? "menu-unfold" : "menu-fold"} />
        </div>
        <a-menu
          mode="inline"
          selectedKeys={[this.checkedMenu.name]}
          onOpenChange={this.onOpenChange}
          openKeys={this.openMenu}

          inlineCollapsed={this.collapsed}
        >
          {this.renderMenu(this.menuData)}
        </a-menu>
      </div>
    );
  },
};
</script>
<style scoped>
  .icon-niandukaohe{
    font-size: 18px  !important;
  }
  .icon-jituan1{
    font-size: 20px  !important;
  }
  .icon-gongzuo1{
    font-size: 20px !important;
  }
  .ant-layout .ant-layout-sider .ant-menu .ant-menu-submenu-selected{
    background: none !important;
  }
  .ant-layout .ant-layout-sider .ant-menu .ant-menu-submenu{
    background: none !important;
  }
</style>