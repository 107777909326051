<script type="text/jsx">
// import HTTP from "@httpServer/index";
import HTTP from "@httpServer/request";
import httpServer from "@httpServer/serverConfig";
import TItem from "./item";
import { formatterParams } from "@utils/utils";
import { mapState } from "vuex";
export default {
  name: "PageHeader",
  data() {
    return {
      popoverShow: false,
      themePopoverShow: false,
      ModalText: "这是我的个人资料",
      visible: false,
      confirmLoading: false,
      userdata: [], //用户详情
      isShowLoaing: false,
      pageNum: 1,
      pageSize: 5,
      listData: [],
      count: 0,
      active: 1,
    };
  },
  computed: {
    ...mapState({
      msgCount: (state) => state.user.msgCount || 0,
    }),
  },
  created() {
    // this.getCount();
    // this.getListData("no_opts");
    this.$store.dispatch("user/userMagCount").then((res) => {
      console.log(res, "????");
    });
  },
  mounted() {
    document.addEventListener("click", this.bodyCloseMenus);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.bodyCloseMenus);
  },
  methods: {
    setPopoverShow() {
      this.popoverShow = !this.popoverShow;
    },
    setThemePopoverShow(type) {
      this.themePopoverShow = type;
    },
    checkTheme(type) {
      document.getElementById("app").className = "";
      document.getElementById("app").classList.add(type);
      this.themePopoverShow = false;
    },
    exit() {
      let that = this;
      that.$store.dispatch("user/userExit", {}).then(() => {
        that.$router.replace({ path: "/login" });
      });
    },
    // 下拉框获取个人信息
    // getUserDetailinfo() {
    //   const { userInfo } = this.$store.getters
    //   this.visible = true;
    //   this.popoverShow = false;
    //   let url = httpServer.userManage.getUserDetailinfo(userInfo.user_id);
    //   console.log(url)
    //   HTTP.get(url).then(res => {
    //     this.userdata = res.data.member_info
    //     console.log(this.userdata)
    //   })
    // },
    handleOk() {
      this.confirmLoading = true;
      this.visible = false;
      this.confirmLoading = false;
    },
    jump() {
      this.popoverShow = false;
      this.$router.push({ path: "/userInfo" });
    },
    onChageItem(num) {
      this.active = num;
      this._getData(num);
    },
    changePop() {
        console.log(this.visible)
      if (this.visible) {
        this.visible = false;
      } else {
        this.visible = true;
        this._getData(1);
      }
    },
    _getData(num) {
      // console.log(num,'num')
      // if(num==1||num==2||num==3){
      //   this
      // }

      this.isShowLoaing = true;
      this.listData = [];
      this.active = num;
      if (num == 1) {
        this.getListData("no_opts");
      } else if (num == 2) {
        this.getListData(false);
      } else if (num == 3) {
        this.getListData(true);
      }
    },
    joinUrl(status) {
      console.log(status, "status");
      var filter = "";
      var baseUrl =
        httpServer.messageCenter.getMessage +
        `&$offset=${(this.pageNum - 1) * this.pageSize}&$limit=${
          this.pageSize
        }`;
      if (status == true) {
        filter = encodeURI("&$filter=read_status eq true");
      } else if (status == false) {
        filter = encodeURI(`&$filter= read_status eq false`);
      }
      baseUrl += filter;
      return baseUrl;
    },
    getListData(status) {
      let url = this.joinUrl(status);
      this.isShowLoaing = true;
      HTTP.get(url, {
        headers: {
          "HD-App-Code": "www.merits.cqzywd.cn",
        },
      }).then((res) => {
        this.isShowLoaing = false;
        if (res.code == "0000") {
          var data = res.data.items;
          this.total = res.data.count;
          this.listData = data;
        }
      });
    },
    onLookAll(num) {
      this.visible = false;
      this.$router.push({
        name: "messageCenter",
        params: {
          num: this.active,
        },
      });
    },

    bodyCloseMenus(e) {
        console.log(e)
      let self = this;
      if (this.$refs.main && !this.$refs.main.contains(e.target)) {
          if (self.visible == true){
              self.visible = false;
          }
      }
    },
  },

  render() {
    const { userInfo } = this.$store.getters;
    return (
      <div class="app-header">
        <div class="logo">
          <img src={require("@public/logo.png").default} />
          土地发展公司质量效率信息化管理系统
        </div>
        <div class="user-info">
          <ul style="display:flex" class="clearfix">
            <li ref="main"
              class="ui_li ui_li_msg"
              title="消息"
              style={{ fontSize: "18px" }}
            >
              <a-popover
                trigger="click"
                placement="bottomRight"
                visible={this.visible}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onClick={() => this.changePop()}
              >
                <template slot="content" style="height:300px;">
                  <div class="h_t_item_wrap">
                    <cmpLoading isShow={this.isShowLoaing} />
                    <TItem
                      onChange={this.onChageItem}
                      list={this.listData}
                      active={this.active}
                      onLookAll={this.onLookAll}
                    />
                  </div>
                </template>
                <span style={{ display: "flex" }} class="icon_wrap">
                  {this.msgCount > 0 && (
                    <span class="number">{this.msgCount}</span>
                  )}
                  <i class="iconfont icon-zu24"></i>
                </span>
              </a-popover>
            </li>
            <span style="margin:0 4px;">|</span>
            <li
              class="user ui_li"
              title={userInfo.user_name}
              style={{ marginLeft: 0 }}
            >
              {/** visible={this.popoverShow} */}
              <a-popover
                trigger="click"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                <template slot="content">
                  {<p onClick={this.jump}>账号设置</p>}
                  <p
                    onClick={() => {
                      this.exit();
                    }}
                  >
                    退出登录
                  </p>
                </template>
                <span onClick={this.setPopoverShow} style={{ display: "flex" }}>
                  <i class="iconfont icon-baseline-account_circle-24px"></i>
                  {/** */}
                  {userInfo.user_name}
                </span>
              </a-popover>
            </li>
          </ul>
        </div>
      </div>
    );
  },
};
</script>
