<script type="text/jsx">
    import './index.less'
    export default {
        name: "CqModal",
        data() {
            return {
                modalConfig: {  //预定义弹框配置
                    visible:false,
                    title:'',
                    bodyStyle:{
                        minHeight:'100px'
                    },
                    getContainer:()=>document.getElementById('app'),
                    cancelText:'取消',
                    okText:'确认'
                }
            }
        },
        props:{
            modalProps:Object,  // 弹框配置
        },
        render() { 
            
            let {props , on} = this.modalProps;
            const modalProps = {
                props:{
                    ...this.modalConfig,
                    ...props
                },
                on
            }
            return <a-modal {...modalProps}>{this.$slots.default}</a-modal>
        }
    }
</script>
