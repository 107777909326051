<template>
  <div class="cmp_progress_container">
    <div class="cmp_progress_wrap">
      <div class="cmp_pro_in_wrap">
        <div class="cmp_pro_in" :style="{ width: percent + '%' }"></div>
      </div>
      <div class="cmp_pro_per">{{ percent }}%</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    percent: {
      type: Number,
      default: 0,
    },
  },
  computed: {},
};
</script>
<style lang="less">
.cmp_progress_container {
  .cmp_progress_wrap {
    display: flex;
    .cmp_pro_in_wrap {
      flex: 1;
      height: 16px;
      background-color: #eeeeee;
      border-radius: 8px;
      overflow: hidden;
      .cmp_pro_in {
        width: 0;
        height: 16px;
        border-radius: 8px;
        background: linear-gradient(
          180deg,
          #b61f23 0%,
          #d43438 53%,
          #b92024 100%
        );
      }
    }
    .cmp_pro_per {
        margin-left:10px;
      width: 40px;
    }
  }
}
</style>