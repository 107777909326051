/**
 * Api 路由集合
 * @author 谭邻宣
 * @date 2020/7/6 14:12
 **/

const configUrl =
  process.env.NODE_ENV === "development"
    ? "dev.cqzywd.cn"
    : "ei.handibigdata.com";
const baseUrl = `/uc/v0.1`;
const messageUrl = `/im/v0.1`;

// const oaUrl = `/v0.1`;
const oaUrl = `/oa/v0.1`
const up_url = `/cs/v0.1`;
const flow = `/flow/v0.1`;





let suid = '';
try {
  var vuex = JSON.parse(sessionStorage.getItem("vuex"));
  // var 
  // console.log(vuex)
  var { userInfo } = vuex.user;
  suid = userInfo.user_id;
  // console.log(suid);
} catch (error) {
  // console.log(error, '获取用户信息失败！！！')
}

///////////////////////////////////////////////////////////////////////////////////////////
var group = '/merits/v0.1/group/';
var company = '/merits/v0.1/company/';
var prb = '/merits/v0.1/prb/';
var assigned = "/merits/v0.1/assigned/";
var monthly = '/merits/v0.1/monthly/'
// //////////////////

var makeChanges = '/merits/v0.1/reduce/'; //调减
var nodeManagement = '/merits/v0.1/nodeManagement/'; //节点

const httpServer = {
  login: {
    //登录相关 
    getTokens: `${baseUrl}/tokens`, //登录前获取token
    getUserInfo: (userId) => `${baseUrl}/users/${userId}/detail`, //请求用户信息
    getUserPer: `${baseUrl}/privileges/user/my`, //请求用户信息
  },

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  // 集团目标任务
  groupTargetasks: {
    getTaskList: `${group}task/list?$count=true`,
    getSingleDetail: (id) => `${group}task/${id}`,
    updateTask: (id) => `${group}task/update/${id}`,
    createTask: `${group}task/insert`,
    deletetask: (id) => `${group}task/${id}`,
    //////////////////////////////////////////////////////////
    getTaskListAll: (year) => `${group}task/all/${year}`,  //获取全部集团目标任务
    updateComplete: (id) => `${group}task/complete/${id}`, //修改集团任务进度,上传附件,开起审批流程
    exportAll: (year) => `${group}task/export/all/${year}`,//导出全部
    taskExport: `${group}task/export`, //自定义导出
    taskReduce: (id) => `${group}task/reduce/${id}`, //集团任务调减,上传调减附件及调减理由
    taskSort: `${group}task/sort`,//集团任务排序
  },
  //公司任务
  companyTargetTask: {
    getTaskList: `${company}task/list?$count=true`,
    getSingleDetail: (id) => `${company}task/${id}`,
    updateTask: (id) => `${company}task/update/${id}`,
    createTask: `${company}task/insert`,
    deletetask: (id) => `${company}task/${id}`,
    getTaskListAll: (dept_id, year) => `${company}task/all/${dept_id}/${year}`,//   dept_id 部门id  获取部门的公司目标任务`
    // 、、、、、、、、、、、、、///////
    updateComplete: (id) => `${company}task/complete/${id}`, //完成情况上传附件,开启审核
    exportAll: (id, year) => `${company}task/export/${id}/${year}`,//导出全部
    taskExport: `${company}task/export`, //自定义导出
    taskReduce: (id) => `${company}task/reduce/${id}`, //公司目标任务调减-开始审批
    taskscore: (id, score) => `${company}task/score/${id}/${score}`,// id 公司任务id   score 扣分值 put
    scoreall: (dept_id, year) => `${company}task/score/all/${dept_id}/${year}`, //获取本部门 公司目标任务扣分合计  get
    taskSort: `${company}task/sort`,//任务排序
  },

  //市规资局任务
  cityTargetTask: {
    getTaskList: `${prb}task/list?$count=true`,
    getSingleDetail: (id) => `${prb}task/${id}`,
    updateTask: (id) => `${prb}task/update/${id}`,
    createTask: `${prb}task/insert`,
    deletetask: (id) => `${prb}task/${id}`,
    updateComplete: (id) => `${prb}task/complete/${id}`, //修改市规资局任务进度,上传附件,开起审批流程
    exportAll: (year) => `${prb}task/export/all/${year}`,//导出全部
    taskExport: `${prb}task/export`, //自定义导出
    taskReduce: (id) => `${prb}task/reduce/${id}`, //市规资局任务调减,上传调减附件及调减理由
    taskSort: `${prb}task/sort`,//任务排序
  },
  //交办任务
  assignedTask: {
    getTaskList: `${assigned}task/list?$count=true`,
    getSingleDetail: (id) => `${assigned}task/${id}`,
    updateTask: (id) => `${assigned}task/update/${id}`,
    createTask: `${assigned}task/insert`,
    deletetask: (id) => `${assigned}task/${id}`,
    // 、、、、、、、、、、、、、、、、、、、
    updateComplete: (id) => `${assigned}task/complete/${id}`,//交办任务完成-开始审批
    exportAll: (id, year) => `${assigned}task/export/${id}/${year}`,//导出全部
    taskExport: `${assigned}task/export`, //自定义导出
    taskReduce: (id) => `${assigned}task/reduce/${id}`, //市规资局任务调减,上传调减附件及调减理由
    taskSort: `${assigned}task/sort`,//任务排序
  },


  // 流程
  process: {
    getProList: `${flow}/v0.1/flow`,
    // addPro: `${flow}/v0.1/flow`,//新增流程定义
    getProDetail: (id) => `${flow}/flow/${id}`,
    updatePro: (id) => `${flow}/flow/${id}`,
    del: (id) => `${flow}/flow/${id}`,
  },
  // 审批流程
  approvalProcess: {
    getTaskDetail: (id) => `${flow}/v0.1/flow/task/${id}`,
    pass: (id) => `${flow}/flow/task/${id}/pass`,
    reject: (id) => `${flow}/flow/task/${id}/reject`,
    draftPass: (id) => `${flow}/flow/task/${id}/draft/pass`,//拟同意
  },


  // 督办
  supervise: {
    getsupervisoryWeek: `/merits/v0.1/supervisoryWeek/list`, //查询时间
    //  getWeektime:(id)=>`/v0.1/supervisoryWeek/listsupervisoryWeek/${id}?$count=true`, //根据时间段id查
    getList: `/merits/v0.1/supervisory/list?$count=true`, //查询督办任务列表
    createSupervisoryTask: `/merits/v0.1/supervisory/createSupervisoryTask`, //新增督办任务
    deleteOne: (id) => `/merits/v0.1/supervisory/${id}`, //删除任务
    getOne: (id) => `/merits/v0.1/supervisory/${id}`, //获取单个
    update: (id) => `/merits/v0.1/supervisory/${id}`
  },
  // 节点
  nodeAdministration: {
    listTree: `${nodeManagement}listTree`, //查询列表
    listNode: `${nodeManagement}listNode?$count=true`,//这是查询列表
    deleteOne: (id) => `${nodeManagement}deleteOne/${id} `, //删除
    createNode: `${nodeManagement}createNode`, //新增
    updateNode: (id) => `${nodeManagement}updateNode/${id} `, //更新节点
    getOne: (id) => `${nodeManagement}getOne/${id}`, //查询单个
  },
  // 月计划
  monthPlan: {

    relevantExport: (mouth, year) => `${monthly}relevant/export/${mouth}/${year}`,//月计划扣分情况导出
    relevantScore: (mouth, year) => `${monthly}relevant/score/${mouth}/${year}`,//月计划扣分情况查询

    getplanList: `/merits/v0.1/monthly/plan/list?$count=true`,// 查询列表
    addPlan: `/merits/v0.1/monthly/plan`, //新建月计划
    deletePlan: (id) => `/merits/v0.1/monthly/plan/${id}`, //删除月计划
    getOne: (id) => `/merits/v0.1/monthly/plan/${id}`, //获取单个月计划
    getOneDetail: (id) => `/merits/v0.1/monthly/plan/detail/${id}`, //获取单个月计划详情
    updateOne: (id) => `/merits/v0.1/monthly/plan/${id}`, //修改部门月计划

    getTimedata: `/merits/v0.1/monthly/relevant/list?$count=true`,//时间


    point: (id) => `/merits/v0.1/monthly/relevant/score/${id}`, //月计划打分
    pointRelevant: (id) => `/merits/v0.1/monthly/relevant/${id}`, //月计划打分详情
    getDetail: (id) => `/merits/v0.1/monthPlan/getDetail/${id}`, //月计划详情
    listMonthlyPlan: (id) => `/merits/v0.1/monthPlan/listMonthlyPlan/${id}`, //月份内计划列表
    createMonthlyPlan: `/merits/v0.1/monthlyPlan/createMonthlyPlan`, //新增月计划
    listMonthPlan: `/merits/v0.1/monthPlan/listMonthPlan`,//月份列表

    currentScore: `/merits/v0.1/monthly/relevant/current/score`, //部门扣分列表

    // /部门月计划提交-审批提交
    planSubmitFlow: (id) => `/merits/v0.1/monthly/plan/submit/flow/${id}`,

    // 部门月计划单个完成
    completeFlow: (id) => `/merits/v0.1/monthly/plan/complete/flow/${id}`,
    //部门调减单个
    reduceFlow: (id) => `/merits/v0.1/monthly/plan/reduce/flow/${id}`,


    // kt  月计划流程任务列表
    getMonthPlanTaskList: `${monthly}flow/list?$count=true`,//月计划流程列表
    getMonthPlanTaskDetail: (id) => `${monthly}flow/${id}`,//月计划流程详情

    //导出
    export: `${monthly}relevant/current/score/export`,
    exportTable: (id) => `${monthly}plan/export/${id}`,

    // 导出 new
    exportnew: (relevant_id) => `${monthly}plan/export/${relevant_id}`,//批量导出
    exportnewAll: (relevant_id) => `${monthly}plan/export/all/${relevant_id}`,//全部导出

    //催办
    notify: `${monthly}notify`,
    findNotify: (id) => `${monthly}notify/${id}`,//    催报数据查询 、、yuejihua
    // `/v0.1/monthly/notify/{plan_id}`  // 催报数据查询  
    getMonthlyFlowDetail: (target) => `${monthly}flow/${target}`,
    taskSort: `${monthly}plan/sort`,//任务排序
    submitFlowList: (relevantId) => `${monthly}plan/submit/flow/list/${relevantId}?$count=true`,//查看可提交月计划列表

    // 查询详情和暂存
    planCache: (id) => `${monthly}plan/cache/${id}`, //查询详情和暂存
    completeCache: (id) => `${monthly}plan/complete/cache/${id}`, //月计划完成暂存
    reduceCache: (id) => `${monthly}plan/reduce/cache/${id}`, //月计划调减暂存
  //  撤销
  planRevoke:(planId)=> `${monthly}plan/revoke/${planId}`,//月计划流程撤销
  },
  // 待我审核
  myJob: {
    taskMy: `/merits/v0.1/flow/task/my?$count=true`, //待我审核列表(url前面是 /merits)
    taskDetail: `/merits/v0.1/flow/task/detail`, //待我审核任务详情( /merits )
    draftCheck: `${flow}/flow/task/draft/pass/check`, //拟同意检查 是否出现
    draftPass: `${flow}/flow/task/batch/draft/pass`,// 流程批量处理-拟同意（/flow）
    batchPass: `${flow}/flow/task/batch/pass`,// 流程批量处理-通过（/flow）
    batchReject: `${flow}/flow/task/batch/reject`,// 流程批量处理-驳回（/flow）
    myEnd: `/merits/v0.1/flow/task/my/end?$count=true`, //我已完成的
  },
  // 调减计划
  reductionPlan: {
    getTaskList: `${makeChanges}task/list?$count=true`, //条件计划列表
    getRelevantList: `${makeChanges}relevant/list?$count=true`, //时间列表
    getTaskOne: (id) => `${makeChanges}task/${id}`, //详情
    export: (id) => `${makeChanges}relevant/export/${id}`, //调减导出
  },
  userManage: {
    usersDept: (dept_id) => `${baseUrl}/users/dept/${dept_id}`, //  dept_id 部门id
    //用户管理
    getParent: (dept_id) => `${baseUrl}/departments/parent/${dept_id}`, //新流程部门接口
    // getDepartments: `${baseUrl}/departments/tree`, //获取部门树
    getDepartments: `${baseUrl}/departments/tree?suid=${suid}`, //获取部门树  
    getAllDepartments: `${baseUrl}/departments/all`,//获取所有部门树 
    getAllDepartmentsOne: `${baseUrl}/departments`, //查询单个部门
    addDepartments: `${baseUrl}/departments`, //新增部门
    editDepartments: (dept_id) => `${baseUrl}/departments/${dept_id}`, //编辑部门 、 删除部门  /v0.1/departments/{dept_id}

    getUserList: `${baseUrl}/users?$count=true&suid=${suid}`, //获取用户列表
    getDeptUserList: (dept_id) => `${baseUrl}/users/dept/${dept_id}`,  //点击部门查询人员列表
    depId2UserList: (dept_id) => `${baseUrl}/users/dept/${dept_id}`,//根据部门id获取用户列表

    lockUser: (user_id) => `${baseUrl}/users/lock/${user_id}?suid=${suid}`, //禁用用户
    enableUser: (user_id) => `${baseUrl}/users/enable/${user_id}`, //启用用户
    getUserDetail: (user_id) => `${baseUrl}/users/${user_id}/detail`, //查询单条用户详情
    getUserDetailinfo: (user_id) => `${baseUrl}/users/${user_id}/detail`, //根据id查询用户详情
    editUser: (user_id) => `${baseUrl}/users/${user_id}`, //编辑用户
    addUser: `${baseUrl}/users?suid=${suid}`, //新增用户
    changePassword: `${baseUrl}/members/password`, //修改密码
    userReset: (memberId) => `${baseUrl}/members/password/reset/${memberId}`, //重置用户密码
    userMyreset: `${baseUrl}/users/my`, //修改自己的信息
    editMyInfo: (id) => `${baseUrl}/users/basic/${id}`,//修改自己的信息1111
    userJurisdiction: `${baseUrl}/privileges/detail/user/my`, //查询角色列表
    // users/dept/{dept_id}
    //查询部门负责人 
    getDeptLeader: (id) => `${baseUrl}/users/dept/leader/${id}`,
    getUserImage: (id) => `${baseUrl}/members/photo/${id}`,//根据id获取头像

    phoneCheck: (phone) => `${baseUrl}/v0.1/users/phone/${phone}/check`,//验证手机号码
  },
  messageCenter: {
    //获取全部消息
    getMessage: `${messageUrl}/message/list/subscribe?$count=true`,
    //未读
    getCount: `${messageUrl}/message/list/count`,
    //阅读单个 
    read: (id) => `${messageUrl}/message/read/${id}`,
    // 阅读多个
    reads: `${messageUrl}/message/read/batch`,
    //阅读全部 
    readAll: `${messageUrl}/message/read/all`,
    // 删除单个
    del: (id) => `${messageUrl}/message/delete/${id}`,
    // 删除多个
    dels: `${messageUrl}/message/delete/batch`
  },

  //签章
  signature: {
    sign: (userId) => `${oaUrl}/sign/get/${userId}`,
    create: `${oaUrl}/sign/create`,
    check: `${oaUrl}/sign/check`,
    update: (userId) => `${oaUrl}/sign/update/${userId}`,
    delete: (userId) => `${oaUrl}/sign/delete/${userId}`,
  },

  roleManagement: {
    // 角色管理
    getroleList: `${baseUrl}/roles?$count=true`,  //获取角色列表
    delRole: (role_id) => `${baseUrl}/roles/${role_id}`, //删除指定角色 
    addRole: `${baseUrl}/roles?suid=${suid}`,
    commonRole: (role_id) => `${baseUrl}/roles/${role_id}?suid=${suid}`, //编辑 /获取指定角色
  },
  carManagement: {
    // 车辆管理
    getCarList: `${oaUrl}/car/list?$count=true`,//查询车辆列表
    getCarone: (id) => `${oaUrl}/car/get/${id}`,  //查询单个车辆
    updateCarone: (id) => `${oaUrl}/car/update/${id}`, //修改车辆信息
    deleteCarone: (id) => `${oaUrl}/car/delete/${id}`, //删除指定车辆
    addCarList: `${oaUrl}/car/create`, //新增车辆
    // 车辆申请
    getCarApplyList: `${oaUrl}/car/apply/list?$count=true`, //查询车辆申请列表
    getCarApplyone: (id) => `${oaUrl}/car/apply/get/${id}`, //查询单个申请列表
    addCarApplyList: `${oaUrl}/car/apply/create`,//新增车辆申请
    updataApplyone: (id) => `${oaUrl}/car/apply/update/${id}`,//修改申请
    deleteApplyone: (id) => `${oaUrl}/car/apply/delete/${id}`,//删除申请
    approval: `${oaUrl}/car/approval/approval`, //审核用车
    getApproval: (id) => `${oaUrl}/car/approval/get/${id}`, //获取审核用车详情
    getAllCarList: `${oaUrl}/car/listAll`,
  },
  conferenceManagement: {
    // 会议管理
    getconferenceList: `${oaUrl}/meeting/list?$count=true`, //查询会议列表
    createMeeting: `${oaUrl}/meeting/create`,//新增会议
    getSingleMeeting: (id) => `${oaUrl}/meeting/get/${id}`,//获取单个会议
    updateMeeting: (id) => `${oaUrl}/meeting/update/${id}`,//修改
    deleteMeeting: (id) => `${oaUrl}/meeting/delete/${id}`,//删除

    //会议申请
    getApplicationList: `${oaUrl}/meeting/apply/list?$count=true`,
    addApplication: `${oaUrl}/meeting/apply/create`,
    delApplication: (id) => `${oaUrl}/meeting/apply/delete/${id}`,
    getSingleApplication: (id) => `${oaUrl}/meeting/apply/get/${id}`,
    updateApplication: (id) => `${oaUrl}/meeting/apply/update/${id}`,
    //判断时间  会议时间检查
    checkApplicationTime: `${oaUrl}/meeting/apply/check`,
    //获取所有会议室
    getAllMeeting: `${oaUrl}/meeting/all`
  },
  officialDocumentManagement: {
    // 公文管理 --- 来文   
    getDocList: `${oaUrl}/document/receive/list?$count=true`,
    //新增
    addDoc: `${oaUrl}/document/receive/create`,
    getSiDoc: (id) => `${oaUrl}/document/receive/get/${id}`,
    search: (message) => `${oaUrl}/document/receive/list/search?message=${message}`,
    //编辑
    updateDoc: (id) => `${oaUrl}/document/receive/update/${id}`,
    ////
    assigned: `${oaUrl}/document/receive/assigned`,//来文科室指派  //来文主办人办理

    //获取全部收文
    getAllReceive: `${oaUrl}/document/receive/all`,
    ////////////////////////////////////
    // 公文管理 --- 发文 
    getPostList: `${oaUrl}/document/list?$count=true`,
    addFw: `${oaUrl}/document/create`,
    getSiFw: (id) => `${oaUrl}/document/get/${id}`,
    editFw: (id) => `${oaUrl}/document/update/${id}`,
    delFw: (id) => `${oaUrl}/document/delete/${id}`,
    searchFw: (val) => `${oaUrl}/document/list/search?message=${val}`,
    //撤销
    revokeFw: (id) => `${flow}/flow/task/${id}/revoke`,
    ////////////////////////////////
    // 文件号管理
    getFilenumerList: `${oaUrl}/document/filenumber/list?$count=true`,
    addFilenumber: `${oaUrl}/document/filenumber/create`,
    delFilenuber: (id) => `${oaUrl}/document/filenumber/${id}`,
    updateFilenuber: (id) => `${oaUrl}/document/filenumber/update/${id}`,
    //获取所有文件号
    getAllFileNumber: `${oaUrl}/document/filenumber/all`


  },

  //文件
  file: {
    fileBase: 'http://merits.cqzywd.cn',
    // fileBase: "http://192.168.0.108",
    upload: `${up_url}/files/upload/single`,
    download: (id) => `${up_url}/files/down/code/${id}?module=oa`,
    fainlDownload: (code) => `${up_url}/files/down/${code}`,
    getFile2Id: (id) => `${up_url}/files/img/${id}`, //根据id获取文件  
    getFileDetail: (id) => `${up_url}/v0.1/files/${id}`,
    //http://dev.cqzywd.cn/cs/v0.1/files/showpic/1309360122757120
    showpic: (id) => `${up_url}/files/showpic/${id}`,
    // showpic: (id) => `${up_url}/files/showpic/${id}`,
    // http://preview.cqzywd.cn/onlinePreview?url=http://preview.cqzywd.cn/files/2020_12_11/034af72f-f338-4ded-a366-b729e7acf078.pdf
    onlinePreview: (url) => `http://preview.cqzywd.cn/onlinePreview?url=http://preview.cqzywd.cn/files/${url}`,//预览
    showPdf: (url) => `http://merits.cqzywd.cn/file${url}`, //pdf预览
    // showPdf:(url)=>`http://192.168.0.108/file${url}`, //pdf预览
    showAll: (url) => `https://view.officeapps.live.com/op/view.aspx?src=http://merits.cqzywd.cn/file${url}`,//预览
    // showAll:(url)=>`https://view.officeapps.live.com/op/view.aspx?src=http://192.168.0.108/file${url}`,//预览
  },
  //获取所有 ----
  all: {
    getAllRoot: `${baseUrl}/v0.1/roles/all`,
    getAllUser: `${baseUrl}/v0.1/users/all`
  }
};
export default httpServer;
