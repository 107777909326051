const getters = {
  userInfo: state => state.user.userInfo, //用户信息
  tokensInfo: state => state.user.tokensInfo, //身份令牌
  perInfo:state=> state.user.perInfo,//权限
  msgCount: state => state.user.msgCount,//未读消息
  coverMaskIsShow:(state)=>state.user.coverMaskIsShow,//弹窗是否是第一次显示
  isShowLoading:state => state.user.isShowLoading,
  msgInfo:state=> state.user.msgInfo,
  jumpPageUrlOpts:state=>state.user.jumpPageUrlOpts,
  innerIsShowLoading:state=> state.user.innerIsShowLoading,
  visitedViews: state => state.tagViews.visitedViews, //views页签信息
  activeView: state => state.tagViews.activeView, //views页签信息
  cachedViews: state => state.tagViews.cachedViews, //view 缓存队列
  isShowMenu: state => state.tagViews.isShowMenu,
  
  
}
export default getters
