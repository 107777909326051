var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 12 }},on:{"submit":_vm.handleSubmit}},[_c('div',{staticStyle:{"height":"60px","border-bottom":"1px solid #dddddd"}},[_c('p',{staticClass:"per_info_title"},[_vm._v("个人信息")])]),_c('div',{staticClass:"per_info_img"},[_c('div',{staticClass:"per_i_i_wrap"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isFileShowLoading),expression:"!isFileShowLoading"}],staticClass:"per_hover"},[_vm._v("\n        +\n        "),_c('input',{ref:"ref_per_input_up",staticClass:"per_h_inp",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onChangeFile}})]),_c('img',{attrs:{"src":_vm.imageUrl}}),(_vm.isFileShowLoading)?_c('a-icon',{attrs:{"type":"loading"}}):_vm._e()],1)]),_c('a-form-item',{attrs:{"label":"账号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['login_name', { required: true, message: '请输入账号!' }]),expression:"['login_name', { required: true, message: '请输入账号!' }]"}],attrs:{"disabled":""}})],1),_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'user_name',
        { rules: [{ required: true, message: '请输入姓名!' }] },
      ]),expression:"[\n        'user_name',\n        { rules: [{ required: true, message: '请输入姓名!' }] },\n      ]"}],attrs:{"placeholder":"请输入姓名","maxLength":28}})],1),_c('a-form-item',{attrs:{"label":"职位"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'position',
        { rules: [{ required: true, message: '请输入职位!' }] },
      ]),expression:"[\n        'position',\n        { rules: [{ required: true, message: '请输入职位!' }] },\n      ]"}],attrs:{"placeholder":"请输入职位","maxLength":28}})],1),_c('a-form-item',{attrs:{"label":"电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'phone',
        { rules: [{ required: true, message: '请输入电话!' }] },
      ]),expression:"[\n        'phone',\n        { rules: [{ required: true, message: '请输入电话!' }] },\n      ]"}],attrs:{"placeholder":"请输入电话","maxLength":11},on:{"change":_vm.onCheckPhone}})],1),_c('a-form-item',{attrs:{"label":"现居住地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'current_address',
        { rules: [{ required: true, message: '请输入现居住地址!' }] },
      ]),expression:"[\n        'current_address',\n        { rules: [{ required: true, message: '请输入现居住地址!' }] },\n      ]"}],attrs:{"placeholder":"请输入现居住地址","maxLength":50}})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 8 }}},[_c('a-button',{staticStyle:{"margin-right":"20px"},on:{"click":_vm.cancle}},[_vm._v(" 取消 ")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 保存 ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }