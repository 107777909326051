<template>
  <!-- 全局配置中文 -->
  <a-locale-provider :locale="locale">
    <div id="app" class="green">
      <div v-if="$route.meta.affix">
        <userInfo />
        <!-- <router-view /> -->
      </div>
      <router-view v-else="!$route.meta.affix"></router-view>
    </div>
  </a-locale-provider>
</template>
<script>
import "./less/common.less";
// import "./less/style/iconfont.css";
// 全局配置中文
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import "moment/locale/zh-cn";
import userInfo from "./pages/userManage/userList/userInfo";
export default {
  name: "App",
  components: { userInfo }, //注册组件
  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>
<style>
/* .ant-btn-primary {
  background-color: #B61F23 !important;
  border-color: #B61F23 !important;
} */

.iconfont{
  font-size: 24px;
}
#app.green
  .ant-layout
  .ant-layout-sider
  .ant-menu
  .ant-menu-submenu
  .ant-menu-item,
#app.green
  .ant-layout
  .ant-layout-sider
  .ant-menu
  .ant-menu-submenu
  .ant-menu-submenu-title {
  border-bottom: 0;
}

/* 顶部标签栏 */
#app.green .ant-layout .ant-layout-content .app-tags-view {
  display: none;
}

#app .ant-layout-sider.ant-layout-sider-dark::-webkit-scrollbar {
  display: none;
}

/*  */
/* ::-webkit-scrollbar{
   display: none;
 }  */

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 3px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 0;
}

::-webkit-scrollbar-thumb { 
  border-radius: 5px; 
  background: #C5C5C5;
}

::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  /* box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1); */
  /* border-radius: 5px;
  background: transparent; */
}
#app .common_in_btn {
  font-size: 15px;
  padding: 0 8px;
}
#app .component::-webkit-scrollbar{
  display: none;
}
 .ant-input-number,.ant-select-selection,.ant-input{
  border-radius: 0 !important;
}
</style>