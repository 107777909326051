<template>
    <a-table v-bind="tableConfig" :components="components"/>
</template>

<script type="text/jsx">
    export default {
        name: "DraggableTable",
        data() {
            return {
                components:{
                    header: {
                        cell: (h, props, children) => { 
                            const {key, ...restProps} = props
                            const col = this.tableConfig.columns.find(col => {
                                const k = col.dataIndex || col.key
                                return k === key
                            })
                            if (!col || !col.width || col.fixed) {
                                return h('th', {...restProps}, [...children])
                            }
                            const dragProps = {
                                key: col.dataIndex || col.key,
                                class: 'table-draggable-handle',
                                attrs: {
                                    w: 10,
                                    x: parseInt(col.width),
                                    z: 1,
                                    axis:'x',
                                    draggable: true,
                                    resizable: false
                                },
                                on: {
                                    dragging: (x, y , z) => {
                                        col.width = Math.max(x, 1);
                                        if(col.funRender){
                                            col.customRender=col.funRender(col.width-32 + 'px')
                                        }
                                    }
                                }
                            }
                            const drag = h('vue-draggable-resizable', {...dragProps})
                            return h('th', {...restProps, class: 'resize-table-th'}, [...children, drag])
                        }
                    }
                }
            }
        },
        props:{
            tableConfig:Object
        }
    }
</script>

<style type="text/less">
    .resize-table-th {
        position: relative;
        .table-draggable-handle {
            height: 100% !important;
            bottom: 0;
            left: auto !important;
            right: -5px;
            cursor: col-resize;
            touch-action: none;
            position: absolute;
            transform: none !important;
        }
    }
</style>