var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 12 }},on:{"submit":_vm.handleSubmit}},[_c('div',{staticStyle:{"height":"60px","margin-bottom":"20px","border-bottom":"1px solid #dddddd"}},[_c('p',{staticStyle:{"margin":"0 0 0 20px","font-size":"16px","color":"#333","text-align":"left","line-height":"60px","font-family":"Microsoft YaHei","font-weight":"400"}},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")])]),_c('a-form-item',{attrs:{"label":"旧密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'password',
        { rules: [{ required: true, message: '请输入密码!' }] },
      ]),expression:"[\n        'password',\n        { rules: [{ required: true, message: '请输入密码!' }] },\n      ]"}],attrs:{"placeholder":"请输入密码"}})],1),_c('a-form-item',{attrs:{"label":"新密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'password_new',
        { rules: [{ required: true, message: '请输入密码!' }] },
      ]),expression:"[\n        'password_new',\n        { rules: [{ required: true, message: '请输入密码!' }] },\n      ]"}],attrs:{"placeholder":"请输入密码"}})],1),_c('a-form-item',{attrs:{"label":"确认新密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'password_affirm',
        { rules: [{ required: true, message: '请输入确认新密码!' }] },
      ]),expression:"[\n        'password_affirm',\n        { rules: [{ required: true, message: '请输入确认新密码!' }] },\n      ]"}],attrs:{"placeholder":"请输入密码"}})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 8 }}},[_c('a-button',{staticStyle:{"margin-right":"20px"},on:{"click":_vm.cancel}},[_vm._v(" 取消 ")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 保存 ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }