<template>
  <a-layout>
    <a-layout-header>
      <PageHeader />
    </a-layout-header>
    <a-layout>
      <a-layout-sider v-model="collapsed" v-show="isShowMenu" width="230">
        <Aside @onChange="onChange" />
      </a-layout-sider>
      <a-layout>
        <a-layout-content>
          <cmpLoading :isShow="isShowLoading && innerIsShowLoading" />
          <Main />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-layout>
</template>
<script>
import PageHeader from "./header";
import Aside from "./aside";
import Main from "./main";
import { mapState } from "vuex";

import "./index.less";
export default {
  name: "PageLayout",
  data() {
    return {
      collapsed: false,
    };
  },
  components: {
    PageHeader, //头部
    Aside, // 左侧菜单
    Main, // body
  },
  created() {
    console.log(this.isShowLoading && this.innerIsShowLoading, "hhhhhhhh");
  },
  computed: {
    isShowMenu() {
      return this.$store.getters.isShowMenu;
    },
    isShowLoading() {
      return this.$store.getters.isShowLoading;
    },
    innerIsShowLoading() {
      return this.$store.getters.innerIsShowLoading;
    },
  },
  methods: {
    onChange(bool) {
      this.collapsed = bool;
    },
  },
};
</script>

<style scoped>
.ant-layout-sider-collapsed {
  width: 60px !important;
  max-width: 60px !important;
  min-width: 60px !important;
  flex: 0 0 60px !important;
}
div /deep/ .ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 17px !important;
}
 /*  */
div /deep/ .ant-menu-inline-collapsed > .ant-menu-submenu> .ant-menu-submenu-title{
    padding: 0 17px !important;
}
div /deep/ .app_aside_menu_wrap {
  /* position: absolute;
  width: 30px;
  background: #3f3f3f;
  left: 95%;
  display: flex;
  top: 43%;
  z-index: 999;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; */
}
</style>