// export const allParentRoot = [
//     "doors-group-task-manage",
//     "doors-company-task-manage",
//     "doors-bureau-task-manage",
//     "doors-assigned-task-manage",
//     "doors-supervisory-task-manage",
//     "doors-deptMonth-task-manage",
//     "doors-reduce-task-manage",
//     "doors-node-manage",

// ]
//  {
//     GROUP:
//     COMMPANY:
    
// };


// 集团目标任务
export const groupTaskRoot = {
    PT:"doors-group-task-manage",
    ADD: "doors-group-add",// 新增
    DEL: "doors-group-delete",// 删除 
    EDIT: "doors-group-edit",// 编辑
    LOOK: "doors-group-view",// 查看
    complete:"doors-group-complete", //完成任务
    reduce:"doors-group-reduce",//调减任务

    approve: "doors-group-approve" ,//流程配置
    export:"doors-group-export",//任务导出
}
// 公司目标任务 
export const companyTaskRoot = { 
    ADD: "doors-company-add",// 新增
    DEL: "doors-company-delete",// 删除 
    EDIT: "doors-company-edit",// 编辑
    LOOK: "doors-company-view",// 查看
    LOOKAll: "doors-company-view-all", //查看全部任务
    complete:"doors-company-complete", //完成任务
    reduce:"doors-company-reduce",//调减任务
    approve: "doors-company-approve" ,//流程配置
    export:"doors-company-export",//任务导出
}

//  市规资局目标任务
export const bureauTaskRoot = {
    PT:"doors-bureau-task-manage",
    ADD: "doors-bureau-add",// 新增
    DEL: "doors-bureau-delete",// 删除 
    EDIT: "doors-bureau-edit",// 编辑
    LOOK: "doors-bureau-view",// 查看
    complete:"doors-bureau-complete", //完成任务
    reduce:"doors-bureau-reduce",//调减任务
    approve: "doors-bureau-approve" ,//流程配置
    export:"doors-bureau-export",//任务导出
}
//交办任务
export const assignedTaskRoot = {
    ADD: "doors-assigned-add",// 新增
    DEL: "doors-assigned-delete",// 删除 
    EDIT: "doors-assigned-edit",// 编辑
    LOOK: "doors-assigned-view",// 查看
    LOOKAll: "doors-assigned-view-all", //查看全部任务
    complete:"doors-assigned-complete", //完成任务
    reduce:"doors-assigned-reduce",//调减任务  
    approve: "doors-assigned-approve" ,//流程配置
    export:"doors-assigned-export",//任务导出  
}
// 督办任务
export const doorsSupervisoryTask={
    ADD:"doors-supervisory-add",
    DEL:"doors-supervisory-delete",
    EDIT:"doors-supervisory-edit",
    LOOK:"doors-supervisory-view",
}

//部门月计划
export const  doorsDeptMonthTask={
    ADD:"doors-deptMonth-add",
    DEL:"doors-deptMonth-delete" ,
    EDIT:"doors-deptMonth-edit",
    LOOK:"doors-deptMonth-view",
    deduction:"doors-deptMonth-deduction",//本月扣分情况
    exportdedu: "doors-deptMonth-current-export",//本月扣分导出
    export:"doors-deptMonth-export",//导出报表
    approve:"doors-deptMonth-submit-approve",//提交审批
    approveEdit:"doors-deptMonth-approve-edit",//审批流程配置
    approveView:"doors-deptMonth-approve-view",//查看审批
    deptMonthFull:"doors-deptMonth-full",//完成计划
    tailoring:"doors-deptMonth-apply-tailoring",//申请调减
    currentDeduction:"doors-deptMonth-current-deduction",//本月扣分

    LOOKAll: "doors-deptMonth-view-all", //查看全部任务     
    // doors-deptMonth-current-export
}

// 调减计划
export const doorsReduceTask={
   LOOK:"doors-reduce-plan-view" ,
   export:"doors-reduce-plan-export",
}

// 节点管理
export const doorsNode={
   ADD:"doors-node-add",
   DEL:"doors-node-delete" ,
   EDIT:"doors-node-edit",
   LOOK:"doors-node-view",
}
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////

/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////

/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
export const allRoot = {
    human: 'doors-human-manage',//人事管理
    statistical: 'doors-statistical-manage',//统计分析
    role: 'doors-role-manage',//角色管理
    document: 'doors-document-manage',//系统公文管理
    meeting: 'doors-meeting-manage',//会议管理
    car: 'doors-car-manage',//车辆管理
}
export const secRoot = {
    statistical: 'doors-statistical-list',//统计分析
    department: 'doors-department-manage',//部门管理
    employee: 'doors-employee-manage',//人员管理
    role: 'doors-role-list',//角色管理
    received: 'doors-received-manage',//收文管理
    issue: 'doors-issue-manage',//发文管理
    fileNo: 'doors-fileNo-manage',//文件号管理
    meetingRoom: 'doors-meetingRoom-manage',//会议室管理
    meetingApply: 'doors-meetingApply-manage',//会议申请
    // supplies:'doors-supplies-list',//物资管理
    car: 'doors-car-list',//车辆管理
    carApply: 'doors-carApply-manage',//用车申请

}
export const departmentManageRoot = {//部门管理
    ADD: "doors-department-add",// 新增部门
    EDIT: "doors-department-edit",// 编辑部门
    DEL: "doors-department-delete",// 删除部门
    LOOK: "doors-department-view",// 查看部门
}

export const employeeManageRoot = {//人员管理
    ADD: "doors-employee-add",// 新增人员
    EDIT: "doors-employee-edit",// 编辑人员
    DEL: "doors-employee-delete",// 删除人员
    LOOK: "doors-employee-view",// 查看人员
    RESET: "doors-employee-reset",//重置密码
}

export const roleManageRoot = {//角色管理
    ADD: "doors-role-add",// 新增角色
    EDIT: "doors-role-edit",// 编辑角色
    DEL: "doors-role-delete",// 删除角色
    LOOK: "doors-role-view",// 查看角色
}

export const statisticalRoot = {//统计分析 
    LOOK: "doors-statistical-view",// 查看统计分析
}


export const conferenceManageRoot = {//会议室管理
    ADD: "doors-meetingRoom-add",// 新增会议室
    EDIT: "doors-meetingRoom-edit",// 编辑会议室
    DEL: "doors-meetingRoom-delete",// 删除会议室
    LOOK: "doors-meetingRoom-view",// 查看会议室
}
export const meetingtoApplyRoot = {//会议室管理
    ADD: "doors-meetingApply-add",// 新增会议
    EDIT: "doors-meetingApply-edit",// 编辑会议
    DEL: "doors-meetingApply-delete",// 删除会议
    LOOK: "doors-meetingApply-view",// 查看会议
}

export const receivedRoot = {//收文管理
    ADD: "doors-received-add",// 新增收文
    EDIT: "doors-received-edit",// 编辑收文
    DEL: "doors-received-delete",// 删除收文
    LOOK: "doors-received-view",// 查看收文
    DOWNLOAD: 'doors-received-down',//下载收文签
}

export const issueRoot = {//发文管理
    ADD: "doors-issue-add",// 新增发文
    EDIT: "doors-issue-edit",// 编辑发文
    DEL: "doors-issue-delete",// 删除发文
    LOOK: "doors-issue-view",// 查看发文
    DOWNLOAD: 'doors-issue-down',//下载收文签
}


export const fileNoRoot = {//文件号管理
    ADD: "doors-fileNo-add",// 新增文件号
    EDIT: "doors-fileNo-edit",// 编辑文件号
    DEL: "doors-fileNo-delete",// 删除文件号
    LOOK: "doors-fileNo-view",// 查看文件号
}

export const flowFloeRoot = {
    SADD: 'doors-received-flow',//收文审核流程
    FADD: 'doors-issue-flow',//发文审核流程
}